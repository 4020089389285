/*------------------------------------*\
    Footer
\*------------------------------------*/
.footer {
  @apply tw-flex-col;
  background-color: #232323;
  color: #a6a6a6;
  margin-top: 15rem;
  padding-bottom: 2rem;

  ul > li,
  ul > li > a {
    font-size: 1.4rem;
  }

  h2.block-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: left;
    color: inherit;
    color: #fff;
  }

  ul.menu.nav,
  ul:not(.contextual-links) {
    display: flex;
    flex-direction: column;
    a {
      &:hover {
        color: #fff;
      }
    }
    li {
      margin-bottom: 0.5rem;
    }
    i {
      margin-right: 1rem;
      color: #ff5308;
    }
  }

  .footer-menu {
    @apply tw-flex tw-flex-wrap tw-flex-col tw-mt-16;
  }

  .region-footer-menu {
    @apply tw-flex tw-flex-wrap tw-w-full;
    gap: 4rem;
  }

  /*
  ======================================================
   Footer Main
  ======================================================
  */
  .footer-two-col {
    ul.menu {
      display: block;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      > li {
        width: 150px;
      }
    }
  }

  /*
  ======================================================
   Footer Mailchimp
  ======================================================
  */
  .footer-content {
    @apply tw-w-full;
    .region {
      @apply tw-flex tw-flex-wrap tw-w-full;
    }
  }

  /*
  ======================================================
   Footer Signup
  ======================================================
  */
  .footer-signup {
    margin-top: -5%;
    background: linear-gradient(89.38deg, #00559f 0%, #00549d 100%);
    @apply tw-flex tw-flex-wrap tw-w-full tw-items-center tw-py-12 tw-py-8;
    h2.block-title {
      @apply tw-flex tw-items-center tw-w-auto tw-normal-case;
      font-size: 2rem;
      flex: 0 0 100%;
      margin-bottom: 0 !important;
    }
    form {
      @apply tw-flex;
      flex: 1;

      > div {
        @apply tw-w-full tw-flex tw-flex-col;
      }

      .control-label {
        @apply tw-hidden;
      }

      .mailchimp-newsletter-mergefields {
        @apply tw-mb-8 tw-grid tw-gap-8;
        flex: 1;
      }

      .form-control {
        @apply tw-border-none tw-bg-transparent;
        border-bottom: 3px dotted rgba(255, 255, 255, 0.5);
        font-size: 2rem;
        &::placeholder {
          color: #6eaee7;
        }
      }

      button {
        @apply tw-h-full tw-border-none;
        background-color: #232323;
        border-radius: 100px;
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        &:hover {
          background-color: #fff;
          color: #232323;
        }
      }
    }
  }

  /*
  ======================================================
   Block : logo
  ======================================================
  */
  .logo {
    @apply tw-mb-16;
  }
  /*
  ======================================================
   Block: Contact
  ======================================================
  */
  .footer-contact {
    color: #fff;
    h2 {
      @apply tw-font-bold;
    }
    h3 {
      color: inherit;
    }
  }

  /*
  ======================================================
   Footer Copyright
  ======================================================
  */
  .footer-copyright {
    margin-top: 1rem;
    color: #fff;
  }

  /** Responsive **/
  @include larger-than($screen-lg) {
    .footer-signup {
      @apply tw-px-16;
      h2.block-title {
        flex: 0 1 340px;
        padding-right: 4rem;
        font-size: 2.5rem;
      }
    }
  }

  @include larger-than($screen-md) {
    .logo {
      margin-right: 4rem;
    }
    h2.block-title {
      margin-bottom: 2rem;
    }
    .footer-signup {
      form > div {
        @apply tw-flex-row;
        .mailchimp-newsletter-mergefields {
          @apply tw-px-10 tw-mb-0 tw-grid-cols-3;
        }
      }
    }
    .footer-menu {
      flex-direction: row;
    }
    .region-footer-menu {
      flex: 1;
      display: flex;
      .block {
        flex: 1;
        &:first-child,
        &:last-child {
          flex: 0 0 auto;
        }
      }
    }
  }
}
