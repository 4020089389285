/*------------------------------------*\
  Hero
\*------------------------------------*/
.hero {
  @apply tw-relative tw-w-full;

  .region-hero {
    @apply tw-relative;
  }

  .slick {
    &__arrow {
      .slick-arrow {
        @include animated;
        background-color: rgba(255, 255, 255, 0.2);
        &:before {
          line-height: 2;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  /** Responsive **/
  @include less-than($screen-lg) {
    .region-hero {
      @apply tw-flex tw-flex-col;
    }
  }
}
