/*------------------------------------*\
  Product - Mixins
\*------------------------------------*/
@mixin product-teaser {
  .product-teaser {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    color: $color-text;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    padding: 2rem;

    .field-name-best-seller-tag {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }

    .field-name-koda-uc-stock-sold-out {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;

      .koda-uc-stock-sold-out {
        text-transform: uppercase;
        background-color: #fff;
        color: $color-text;
        padding: 0 8px;
        display: flex;
        margin: 10px;
        line-height: 1.6;
      }
    }

    .field-name-koda-uc-stock-low-stock {
      .koda-uc-stock-low-stock {
        text-transform: uppercase;
        font-size: 1.2rem;
      }
    }

    &__images {
      position: relative;
      padding-bottom: 100%;
      height: auto;
      width: 100%;
      @include animated;
      @include imgZoomHover;

      &:hover {
        > a {
          &:nth-child(2) {
            @include animated;
            opacity: 1;
          }

          img {
            object-fit: contain;
          }
        }
      }

      /*------------------------------------*\
      Styled ready for multiple image teaser
      \*------------------------------------*/

      > div,
      > span,
      > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include animated;

        &:first-child {
          z-index: 5;
        }

        &:nth-child(2) {
          z-index: 10;
          opacity: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 10;
        background-color: #fff;
      }
    }

    /** Teaser Info **/
    &__info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-top: 1rem;
      height: 100%;

      > h3 {
        width: 100%;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 0;

        a {
          position: relative;
          width: 100%;
          &:hover {
            color: $color-tertiary;
          }
        }
      }

      .term-teaser-title {
        text-align: center;
        font-size: 1.6rem;
        color: #474747;
        margin-top: 1rem;
        margin-bottom: 0;

        > a {
          &:hover {
            color: $color-tertiary;
          }
        }
      }
    }
    /** Range Button **/
    .range-btn {
      margin-top: auto;
      font-size: 1.6rem;
      height: 4.8rem;
      @extend .btn-tertiary;
    }

    /** Product Sell Prive **/
    .product-info.sell-price {
      padding-top: 1rem;
      margin-top: auto;

      .uc-price-prefix {
        font-size: 1.4rem;
        color: #999;
      }

      .uc-price {
        font-weight: 800;
        font-size: 2.2rem;
        color: #000;
      }

      .uc-price-suffix {
        color: #000;
        font-weight: 600;
        font-size: 1.4rem;
      }
    }

    /** Field File **/
    .field-name-field-file {
      width: 100%;

      .field-item {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .file {
        .file-icon,
        .file-size {
          display: none;
        }

        > a {
          position: relative;
          text-align: center;
          color: $color-orange;

          &:after {
            content: '\f33d';
            margin-left: 0.5rem;
            font-family: $font-awesome;
          }
        }
      }
    }

    /** Price **/
    &__price {
      flex: 0 0 100%;
      display: flex;
      font-weight: bold;
    }

    /** BUtton **/
    .add-to-cart {
      padding-top: 0.5rem;

      > form {
        width: 100%;

        > div {
          width: 100%;
          display: flex;
          align-items: flex-end;

          .form-item {
            display: flex;
            width: auto;
            flex: 1;
            display: flex;
            align-items: center;
          }

          .control-label {
            margin-bottom: 0.5rem;
            font-weight: 600;
            display: flex;
            font-size: 0;
            margin-bottom: 0;

            &::before {
              content: 'Qty : ';
              font-size: 1.6rem;
              width: 4rem;
            }
          }

          input.form-text {
            border-radius: 0;
            border: 1px solid #d4d4d4;
            font-size: 1.6rem;
            font-weight: 600;
            text-align: center;
            width: 4rem;
          }

          .wrap-js-qty {
            .increment {
              width: 2rem !important;
            }
          }

          .panel.form-wrapper {
            .panel-body {
              width: 100%;
            }
          }
        }
      }

      .form-actions {
        margin-left: 0.5rem;
        flex: 1;

        button {
          border-radius: 6px;
          width: 100%;
          height: 4.8rem;
          min-width: auto;
          font-size: 0;
          line-height: 1;
          padding-left: 12px;
          padding-right: 12px;
          @extend .btn-tertiary;
          @include animated;

          &::before {
            content: url('../../images/icon--header-cart.svg');
          }

          &.progress-disabled {
            background-color: #f39b15;
            &::before {
              content: url('../../images/icon--update-white.svg');
              @include animate-rotate;
            }
          }

          .ajax-progress {
            display: none;
          }
        }
        .uc-ajax-cart-alt-status-messages {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          padding: 2rem;
          z-index: 30;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.7);
          @extend .hide-after3s;
        }

        .alert {
          margin: 0;
          padding: 2rem;
          font-size: 1.4rem;
          letter-spacing: 1px;

          .close {
            display: none;
          }
        }
      }
    }
  }
}

/*
======================================================
 Global Tabs
======================================================
*/
@mixin globalTabs {
  margin-top: 2rem;

  > .horizontal-tabs {
    .select-tabs {
      display: none;
    }

    .horizontal-tabs-pane {
      padding: 2rem 0;
    }

    > .horizontal-tabs-panes {
      width: 100%;

      > .horizontal-tabs-pane {
        padding-top: 2rem;
      }

      .group-size-fit {
        .panel-body {
          > .field {
            padding: 0;

            .field-label {
              width: 15rem;
            }

            &:not(:first-child) {
              border-top: 1px dashed #dedede;
            }
          }
        }
      }

      .panel-body {
        .horizontal-tabs-list {
          > li {
            border-bottom: 1px solid #dddddd;

            &.selected {
              border-color: $color-orange;

              > a {
                strong {
                  color: $color-orange;
                }
              }
            }

            &:last-child {
              > a {
                margin-right: 0;
              }
            }

            > a {
              position: relative;
              height: 100%;
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        }
      }
    }

    @include larger-than($screen-md) {
      .horizontal-tabs-pane {
        padding: 4rem 0;
      }

      > ul.horizontal-tabs-list {
        border-bottom: 1px solid #262626;

        li {
          margin-top: 4px;
          margin-bottom: -1px;
          border-radius: 6px 6px 0px 0px;

          &:not(.first-child) {
            margin-right: 1px;
          }

          &.selected {
            background-color: $color-secondary;
          }

          &:not(.selected) {
            background: #262626;
          }

          a {
            padding: 1rem 1.5rem !important;
            background: unset;
            color: #fff;
            font-size: 1.5rem;
            text-transform: none;
            text-align: center;

            * {
              color: #fff;
              font-weight: normal;
            }
          }
        }
      }
    }

    /** Mobile **/
    @include less-than($screen-md) {
      display: flex;
      flex-wrap: wrap;
      min-height: 35px;

      .select-tabs {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 1;
        top: 0;
        right: 0;
        z-index: 12;
        width: 35px;
        height: 46px;
        border-left: 1px solid $color-tertiary;

        .fa {
          color: #fff;
          font-size: 30px;
          font-weight: 200;
          line-height: 1;
          @include animated;
        }

        &.active {
          .fa {
            transform: rotate(180deg) !important;
          }
        }
      }

      > ul.horizontal-tabs-list {
        position: absolute;
        left: 0;
        order: 0;
        flex: 1;
        z-index: 10;
        flex-direction: column;
        background-color: $color-orange;

        > li {
          color: #fff;
          border-bottom: 1px solid #e87409;

          &.selected {
            order: 0;
            padding: 0;
          }

          > a {
            text-transform: none;
            font-weight: normal;
            color: #fff;
            background: $color-orange;
            font-size: 20px;

            strong {
              font-weight: normal;
              color: #fff;
            }

            &:hover,
            &:active {
              background: $color-tertiary;
            }
          }
        }

        /** Extracted **/
        &.extracted {
          > li {
            order: 1;

            &.selected {
              order: 0;
            }
          }
        }

        &:not(.extracted) {
          > li {
            order: 1;

            &:not(.selected) {
              display: none;
            }
          }
        }
      }

      > .horizontal-tabs-panes {
        margin-top: 35px;
      }
    }
  }
}
