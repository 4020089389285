/*------------------------------------*\
  MEGA Menu
\*------------------------------------*/
#app-custom-menu {
  position: relative;
  display: flex;
  height: 100%;

  .mega-menu {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    /** Common **/
    > .mega-menu__list-item {
      display: flex;

      > a {
        display: flex;
        align-items: center;
        color: #000;
        padding: 1rem clamp(0.2rem, 1vw, 2rem);
        font-size: $top-menu-font-size;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.04em;

        &:hover {
          color: $color-primary;
        }
      }
    }

    .mega-menu__list-item {
      &:hover {
        > .mega-menu__wrapper {
          display: flex;
        }
      }

      > a {
        .submenu-indicator {
          margin-left: auto;

          &::after {
            content: '\f105';
            font-family: $font-awesome;
            font-style: normal;
            font-size: 16px;
            font-weight: 100;
            line-height: 1;
            width: 10px;
            color: inherit;
            display: inline-block;
          }
        }
      }
    }

    &__list {
      width: 280px;

      &.top-menu {
        width: 20%;
      }

      ul {
        width: 100%;
      }
    }

    > .mega-menu__dropdown {
      width: auto;
      flex: 1;
    }

    &__wrapper {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      width: calc(100vw - (100vw - 1440px));
      z-index: 50;
      padding: 0;
      background-color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      // Under mega menu

      .mega-menu__list {
        padding: 2rem;

        ul {
          border: 1px solid #eae9e5;
          border-radius: 6px;

          > li {
            padding: 1.5rem 1rem 1.5rem 1.5rem;
            min-height: 4.6rem;

            &:not(:first-child) {
              border-top: 1px solid #eae9e5;
            }

            > a {
              color: #000;
              display: flex;
              padding: 0;
              line-height: 1;
            }
          }
        }
      }

      .mega-menu__list-item {
        position: relative;
        padding: 1rem;

        .sub-menu {
          position: absolute;
          left: 300px;
          display: none;
        }

        &:hover,
        &.active {
          background-color: $color-primary;

          > a,
          > a > .submenu-indicator {
            color: #fff;
          }

          > .sub-menu {
            display: block;

            > .mega-menu__list {
              padding: 0;
            }
          }
        }
      }
    }

    &__dropdown {
      display: flex;
      position: relative;
      right: 0;
      flex: 1;

      .mega-menu__list {
        width: 25%;
      }

      a {
        width: 100%;
        justify-content: flex-start;
        align-items: center;

        .mega-menu__thumbnail {
          height: 4rem;
          width: 4rem;
          margin-right: 1rem;
          flex-shrink: 0;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }

      .mega-menu__list {
        height: 100%;

        > ul {
          overflow-y: scroll;
          max-height: 100%;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 1px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 0;
          }
        }
      }
    }
  }
}
