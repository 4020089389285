/*------------------------------------*\
  Table
\*------------------------------------*/

.table-responsive {
  > table {
    font-size: inherit;
    margin-top: 4rem;
    width: 100%;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.table-no-border {
      tr {
        td {
          border: none;
        }
      }
    }

    &.table-alternate {
      tr {
        &:nth-child(odd) {
          td {
            background-color: $color-tertiary;
          }
        }
        td {
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }

    td {
      padding: 1.5rem 2.5rem;
      border: 1px solid rgba(74, 76, 78, 0.6);

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }

  @include less-than($screen-lg) {
    min-height: 0.01%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table {
      margin-bottom: 0;
      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
