/*------------------------------------*\
  Header Search
\*------------------------------------*/
.header-search {
  @apply tw-flex tw-mr-auto;

  form,
  form > div {
    @apply tw-w-full tw-m-0;
  }
  /** Autocomplete Dropdown**/
  .views-exposed-widget {
    .form-autocomplete {
      position: relative;

      .view-mode-teaser_autocomplete {
        position: relative;
        display: flex !important;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        .field {
          display: flex;
          &.field-name-uc-product-image {
            display: flex;
            flex: 0 0 50px;
            img {
              width: 100%;
              height: auto;
            }
          }
          &.field-name-title {
            display: flex;
            flex: 1;
            align-items: center;
            font-size: 14px;
            white-space: normal;
            h2 {
              white-space: normal;
              float: left;
              display: flex;
              word-break: break-all;
              font-size: 14px;
              width: 100%;
              padding-left: 2rem;
            }
          }
        }
      }

      .dropdown {
        z-index: 999;
        ul {
          max-height: 70vh;
          overflow-y: auto;

          li {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0;

            .product-title {
              position: relative;
              font-size: 1.35rem;
              width: 100%;
              word-wrap: break-word;
              overflow-wrap: break-word;
              white-space: normal;
            }

            &:hover {
              * {
                background-color: unset !important;
                color: $color-primary !important;
              }
            }

            &:not(:first-child) {
              border-top: 1px dashed #f4f4f4;
            }
          }
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 50;
          display: none;
          float: left;
          min-width: 160px;
          padding: 5px 0;
          margin: 2px 0 0;
          list-style: none;
          text-align: left;
          background-color: #fff;
          border: 1px solid #d4d4d4;
          border-radius: 3px;
          background-clip: padding-box;
          > li > a {
            display: block;
            padding: 4px 20px;
            clear: both;
            color: #000;
            font-size: 1.6rem;
            font-weight: normal;
            white-space: nowrap;
            &:hover {
              background-color: $color-secondary;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .views-exposed-form {
    @apply tw-w-full;

    .views-exposed-widgets {
      @apply tw-w-full tw-mr-0 tw-mb-0 tw-flex tw-flex-wrap;
    }

    .views-exposed-widget {
      padding: 0;
      flex: 1;
      input[type='text'],
      input[type='password'],
      input[type='email'] {
        height: 4.38rem;
        border-radius: 4px 0 0 4px;
        &::placeholder {
          color: #999999;
          opacity: 1;
        }
        &::-ms-input-placeholder {
          color: #999999;
        }
      }

      &.views-submit-button {
        flex: 0 0 38px;
      }

      .form-submit {
        background-color: #000;
        border-radius: 0 4px 4px 0;
        border: none;
        height: 4.38rem;
        margin: 0;
        min-width: auto;
        font-size: 0;
        color: transparent;
        &:hover {
          background-color: $color-secondary;
        }
        &::before {
          font-family: $font-awesome;
          content: '\f002';
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  &::after {
    @apply tw-hidden;
  }

  // Responsive
  @include larger-than($screen-sm) {
    padding-left: 1rem;
  }

  @include larger-than($screen-md) {
    flex: 0 0 90%;
  }

  @include larger-than($screen-xxl) {
    flex: 0 0 54rem;
    padding-left: 4rem;
  }
}
