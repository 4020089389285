/*------------------------------------*\
  Designers
\*------------------------------------*/

/** Designer Taxonomy **/
.designer-taxonomy {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include imgZoomHover;

  &:hover {
    .field-name-field-category-image {
      .field-item {
        &::before {
          opacity: 1;
        }
      }
    }

    .designer-taxonomy__content {
      .vprod-label {
        padding-left: 1.5rem;
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .field-name-field-category-image {
    .field-item {
      &::before {
        @include animated;
        content: '';
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
  &__content {
    @include animated;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    background: linear-gradient(
      360deg,
      rgba(1, 22, 39, 0.4) 11.69%,
      rgba(1, 22, 39, 0) 49.86%
    );
    h2 {
      color: inherit;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .vprod-label {
      @include animated;
      position: relative;
      font-size: 1.4rem;
      margin-top: 0.5rem;
      text-transform: uppercase;
      &::before {
        @include animated;
        position: absolute;
        content: '\f105';
        font-family: $font-awesome;
        margin-right: 0.5rem;
        left: 0;
        opacity: 0;
      }
    }

    .field-name-more-link {
      a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        color: transparent;
      }
    }
  }
}

body {
  &.designers {
    .page_header {
      display: none;
    }
  }
}

.designers {
  .group-bio {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    margin-bottom: 3em;

    .field-name-field-bio-image {
      width: 100%;
      margin-bottom: 2rem;
      .field-items,
      .field-item,
      img {
        width: 100%;
      }
    }

    .field-name-title {
      h2 {
        text-transform: uppercase;
        font-size: 3rem;
        line-height: 1;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 2px solid $color-secondary;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    /** Responsive **/
    @include larger-than($screen-md) {
      .field-name-field-bio-image {
        flex: 0 0 30rem;
        width: 30rem;
        margin-right: 3em;
      }
    }
  }
}
