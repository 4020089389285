/*------------------------------------*\
  Merchandise
\*------------------------------------*/
.merch {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  &__image {
    img {
      margin: 0 auto;
    }
  }
  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .media {
      .content {
        display: flex;
        justify-content: center;
      }
    }
  }

  @include larger-than($screen-md) {
    flex-direction: row;
    &__image {
      flex-basis: 30rem;
    }
  }
}
