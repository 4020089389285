/*------------------------------------*\
    Components: alert
\*------------------------------------*/
.alert {
  position: relative;
  background: $color-primary;
  border-radius: 4px;
  padding: 1rem 2.5rem 1rem 1rem;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.2;
  display: inline-block;
  width: 100%;
  align-items: center;
  margin: 1rem 0;

  a {
    text-decoration: none;
  }

  .close {
    position: absolute;
    cursor: pointer;
    right: 1rem;
    top: 0;
    font-size: 2rem;
    transition: all 0.3s;
    text-decoration: none;
    padding: 0;
    margin-top: 4px;
    height: auto;
    &:hover {
      color: #000;
    }
  }

  &.alert-success {
    background-color: $alert-success;
  }
  &.alert-danger {
    background-color: $alert-danger;
  }
  &.alert-warning {
    background-color: $alert-warning;
  }
  &.alert-feedback {
    background-color: $alert-feedback;
  }
  &.alert-link {
    background-color: $alert-link;
  }
}
