/*------------------------------------*\
  About Page
\*------------------------------------*/
.page-node.about-hansen {
  .about-image {
    margin-bottom: 3rem;

    @include larger-than($screen-md) {
      margin-right: 4rem;
      float: left;
    }
  }
  .about-order {
    @include larger-than($screen-md) {
      order: 1;
    }
  }
}
