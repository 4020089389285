/*------------------------------------*\
  Header Collapse
\*------------------------------------*/
body {
  &.page-scroll {
    .header {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 50;
      @extend .animate__fadeInDown;
    }
  }
  .header {
    @extend .animate__animated;
  }
}
