/*------------------------------------*\
  File: Koda Accordion
\*------------------------------------*/
.koda-accordion {
  // Header
  .accordion-header {
    @include animated;
    cursor: pointer;
    display: flex;
    color: $color-primary;
    margin-top: 1rem;

    &::before {
      //@include animated;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '\2b';
      color: $color-orange;
      font-family: $font-awesome;
      margin-right: 1rem;
      line-height: 1;
      width: 20px;
      height: 20px;
      font-size: 1.8rem;
      padding-left: 2px;
    }
  }
  // Open
  &.open {
    > .accordion-header {
      &::before {
        content: '\f068';
        font-size: 1rem;
      }
    }
    > .field.field-name-body {
      @include animated;
      display: block;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }

  > .field.field-name-body {
    height: 0px;
    padding-left: 4rem;
    color: #4e4e4e;
    visibility: hidden;
    opacity: 0;
  }
}
