/*------------------------------------*\
  Webform
\*------------------------------------*/
form {
  &.webform-submission-form {
    @apply tw-flex tw-flex-wrap tw-w-full;
    .form-item {
      display: block;
      padding-bottom: 1rem;
      width: 100%;
      .control-label {
        color: #003554;
      }
    }

    .webform-button--submit {
      background-color: $color-primary;
      border-radius: 2px !important;
      border-color: darken($color-primary, 5%);
      text-transform: uppercase;
      font-size: 1.6rem;
      font-family: 'Exo', sans-serif;
      font-weight: normal;
      min-width: 19.6rem;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }

    .form-actions {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        margin-right: 0;
      }
    }

    /** Responsive **/
    @include larger-than($screen-md) {
      .form-item {
        &.col-2 {
          width: 50%;
        }
        &.col-left {
          padding-right: 1rem;
        }
        &.col-right {
          padding-left: 1rem;
        }
      }

      .form-actions {
        justify-content: flex-end;
        > button {
          width: auto;
        }
      }
    }
  }

  &.webform-client-form {
    > div {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    .form-item {
      margin-bottom: 1rem;
      width: 100%;
      order: 0;
      &.form-type-date,
      &.form-type-webform-time {
        .webform-container-inline {
          display: flex;
          padding: 1rem;
          border: 1px solid #78a1bb;
          border-radius: 3px;
          height: 48px;
          color: #003554;
          .form-item {
            flex-basis: auto !important;
            width: auto !important;
            padding-right: 1rem;
            &.form-type-select {
              height: 100%;
              line-height: 1;
            }
          }
          .chosen-container {
            width: auto !important;
            min-width: 50% !important;
            font-size: 1.6rem;
          }
          .chosen-single {
            border: none;
            background: unset;
            box-shadow: none;
            color: #003554;
            > div {
              b {
                background: none;
                &::before {
                  content: '\f078';
                  font-family: $font-awesome;
                  font-size: 10px;
                  font-weight: bold !important;
                }
              }
            }
          }
          .form-radios {
            display: flex;
            label.control-label {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              padding: 0;
              input {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }

    .form-actions {
      width: 100%;
      order: 2;
    }

    .captcha {
      width: 100%;
      order: 1;
      margin-bottom: 2rem;
      margin-top: 0;
    }

    @include larger-than($screen-md) {
      .form-item {
        &.col-2 {
          width: 50%;
        }

        &.col-left {
          padding-right: 1rem;
        }

        &.col-right {
          padding-left: 1rem;
        }
      }
    }
  }
}
