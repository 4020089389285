/*------------------------------------*\
  Contact Page
\*------------------------------------*/
.page-node.contact {
  .content .content-wrapper {
    @include larger-than($screen-md) {
      flex-wrap: wrap;
      .region-content,
      .region-content-right {
        flex: 0 0 50%;
      }
    }
  }

  /** Webform **/
  .webform-client-form {
    display: flex;
    flex-direction: column;

    > div {
      > .form-item {
        margin-bottom: 2.5rem;
        .form-item {
          margin-bottom: 0;
        }
        select {
          height: 4.8rem;
        }
      }
    }

    .form-actions {
      order: 1;
      button.webform-submit {
        min-width: auto;
        background: #212121;
        border-color: #212121;
      }
    }

    .captcha {
      order: 0;
      margin-bottom: 2rem;
    }
  }

  /** Quick Tabs **/
  .contact {
    &--tabs {
      ul.quicktabs-tabs {
        width: 100%;
      }
      h3 {
        text-transform: uppercase;
        font-size: 1.6rem;
      }
    }
    &--regions {
      display: grid;
      gap: 2rem;
      @include larger-than($screen-lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      > div {
        > .contact--info {
          gap: 2rem;
        }
      }
    }
    &--info {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 0 2rem 0;
      gap: 3rem;

      img {
        width: 100%;
      }

      .image {
        width: 100%;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        color: #555555;
        label {
          width: 100%;
          font-weight: 600;
        }
      }

      h4 {
        font-size: 1.6rem;
        margin-bottom: 0;
        color: #555555;
      }

      a {
        color: $color-secondary;
        &:hover {
          text-decoration: underline;
        }
        &.phone {
          display: flex;
          margin-top: 0.5rem;
          font-size: 1.8rem;
          font-weight: 400;
          color: #2c2c2c;
          &::before {
            content: '\f095';
            color: #00559f;
            font-family: $font-awesome;
            font-weight: 700;
            margin-right: 0.5rem;
          }
        }
      }

      ul {
        list-style: disc;
        font-size: 1.2rem;
        li {
          margin-left: 1.5rem;
        }
      }

      @include larger-than($screen-md) {
        .image {
          flex: 1;
        }
        .details {
          flex: 0 0 40%;
        }
        .details-mini {
          flex: 0 0 60%;
        }
        .cols {
          flex: 0 0 calc(50% - 2rem);
          background-color: red;
        }
      }
    }
  }
}
