/*------------------------------------*\
  Pagination
\*------------------------------------*/
.pagination {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  @apply tw-w-full
    tw-flex
    tw-justify-center
    tw-mt-16
    tw-pt-8
    tw-pb-8;
  li {
    @apply tw-py-2 tw-px-3;
    color: #000;
    a {
      color: #000;
    }
    &.active {
      background-color: $color-primary;
      color: #fff;
      * {
        color: inherit;
      }
    }
  }
}
