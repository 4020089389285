/*------------------------------------*\
  Card
\*------------------------------------*/
.card {
  /** Common Teaser **/
  &--teaser {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    height: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    align-items: center;
    color: #555555;
    font-size: 14px;

    .field-name-field-thumbnail-image,
    .field-name-field-cover-image {
      margin-bottom: 1rem;
      @include animated;
      @include imgZoomHover;
      img {
        height: 18rem;
        object-fit: cover;
      }
    }
    .field-name-title {
      position: relative;
      width: 100%;
      text-align: center;
      h3 {
        font-size: 1.8rem;
      }
      h4 {
        font-size: 1.4rem;
      }
      h2,
      h3,
      h4 {
        color: #474747;
        a {
          &:hover {
            color: #000;
          }
        }
      }
    }
    .field-name-body {
      p {
        color: inherit;
      }
      .media,
      img {
        display: none;
      }
    }
    .field-name-node-link {
      position: relative;
      width: 100%;
      margin-top: auto;
      a {
        width: 100%;
        text-transform: uppercase;
        font-weight: 700;
        @extend .btn-tertiary;
      }
    }
    .video-link {
      margin-top: auto;
      color: $color-orange;
      &:hover {
        color: #000;
      }
      &::after {
        margin-left: 0.5rem;
        content: '\f144';
        font-family: $font-awesome;
      }
    }
  }

  /** Catalogue **/
  &--catalogue {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    width: 100%;
    height: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    align-items: center;
    color: #353535;
    font-size: 14px;

    .field-name-field-cover-image {
      margin-bottom: 2rem;
      @include imgZoomHover;
      img {
        max-width: 100%;
        margin: 0 auto;
      }
    }
    h2 {
      font-size: 14px;
      color: inherit;
      margin-bottom: 0;
      text-align: center;
    }
    .field-name-field-last-print {
      display: flex;
      align-items: center;
      justify-content: center;
      * {
        font-style: italic;
        font-weight: 400;
      }
      &::before,
      &::after {
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: inherit;
        height: 100%;
        width: 8px;
        visibility: visible;
      }
      &::before {
        content: '(';
      }
      &::after {
        content: ')';
      }
    }

    .field-name-custom-catalogue-doc-file,
    .field-name-international-doc-file,
    .field-type-file {
      margin-top: auto;
      font-weight: 600;
      color: $color-orange;
      a {
        display: flex;
        margin-top: 1.5rem;
        &::after {
          content: '\f33d';
          font-family: $font-awesome;
          margin-left: 0.5rem;
        }
      }
      .file-icon,
      .file-size {
        display: none;
      }
    }
  }
}
