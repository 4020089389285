/*------------------------------------*\
  Mobile Menu
\*------------------------------------*/

.mmenu-nav.mm-basic {
  background-color: $color-primary;
  .mm-heater,
  .mm-footer {
    background-color: $color-primary;
  }
  > .mm-list {
    padding: 0;

    > .mm-subtitle {
      > .mm-subclose {
        color: #fff;
        background-color: darken($color-primary, 1%);
        &::before {
          border-color: #fff;
          margin-bottom: -3px;
          border-left: 2px solid #fff;
        }
      }
    }

    li {
      border-bottom: none;
      font-family: $base-font;
      color: #fff;
      font-size: 1.6rem;
      a {
        font-family: $base-font;
        color: #fff;
        font-size: inherit;
      }
      > span {
        padding: 0;
        font-size: inherit;
      }
    }

    .mobile-logo {
      padding: 0.5rem 0;
      img {
        margin: 0 auto;
      }
    }
  }
}

html.mm-front.mm-basic .mm-slideout {
  z-index: 5 !important;
  cursor: pointer;
  opacity: 0.2;
}
