/*------------------------------------*\
  Masquerade
\*------------------------------------*/
.block-masquerade {
  position: relative;
  width: 100%;
  float: left;
  display: block;

  &.masquerade-fixed {
    position: fixed;
    bottom: 0;
    background-color: rgba(143, 143, 143, 0.9);
    width: 100%;
    padding: 4px;
    left: 0;
    z-index: 99;
  }

  .block-wrapper {
    position: relative;
    width: 100%;
  }

  .block-title {
    display: none;
  }

  form {
    .dropdown {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
      left: 0;
      bottom: 100% !important;
      height: auto;
      margin: 0 !important;
      position: relative;
      width: 100%;

      .dropdown-menu {
        width: 100%;
        height: 100%;
        background-color: #000;
        max-height: 200px;
        overflow-y: scroll;
        padding: 8px;
        border-radius: 0;
        top: auto;
        bottom: 100% !important;
        z-index: 99;
        float: left;
        > li {
          position: relative;
          width: 100%;
        }
      }

      a {
        position: relative;
        float: left;
        width: 100%;
        display: block;
        padding: 0 8px;
        font-size: 12px !important;
      }
    }

    > div {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      @include larger-than($screen-sm) {
        flex-direction: row;
      }
    }

    span {
      &.input-group-addon {
        display: none;
      }
    }

    .input-group {
      display: flex;
      flex-direction: row;
      .form-text {
        border: none;
        border-radius: 0 !important;
        height: 34px !important;
      }
    }

    .container-inline {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 0 !important;
      width: auto !important;
      align-items: center;
      .form-submit {
        height: 34px;
        border-radius: 0 !important;
      }
    }

    .form-item {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      height: 100%;
      margin: 0;
      flex: 1;
      text-align: left;
      //min-height: 40px;

      .description {
        height: 100%;
        padding: 0 8px;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;

        a {
          position: relative;
          padding-left: 8px;
          padding-bottom: 0;
          margin-bottom: 0;
          text-decoration: underline;
        }

        #quick_switch_links {
          margin-left: 15px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: stretch;
        }

        ul li {
          margin-bottom: 0;
        }
      }
    }

    .form-control {
      position: relative;
      border-radius: 0;
      margin-bottom: 0 !important;
    }

    .input-group-addon {
      display: none;
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // justify-content: center;
      // align-items: center;
      // position: relative;
      // width: 40px;
      // padding: 0;
      // border-radius: 0;
      // .glyphicon {
      //     font-size: 12px;
      // }
    }

    .input-group {
      position: relative;
      display: flex;
      flex-direction: row;
    }

    .btn.form-submit {
      border-radius: 0;
      min-height: 35px;
    }
  }
}
