/*------------------------------------*\
  File: Sidebar For Product
\*------------------------------------*/

.sidebar {
  background-color: #434343;
  color: #fff;
  padding: 3rem;
  font-size: 1.6rem;
  .region {
    .block {
      margin-bottom: 4rem;
      padding-bottom: 4rem;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      .block-title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }

      .menu {
        li {
          color: #1a1a1a;
          &.active-trail {
            > a {
              &::before {
                content: '\f00c';
                color: #fff;
                background-color: #434343;
              }
            }
          }
          a {
            display: flex;
            align-items: center;
            &::before {
              content: '';
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $font-awesome;
              width: 15px;
              height: 15px;
              border: 1px solid #434343;
              margin-right: 1rem;
              line-height: 1;
              text-align: center;
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
      }

      ul[class*='facetapi'] {
        li {
          color: #1a1a1a;
          padding-bottom: 0;
          input[type='checkbox'] {
            background: none !important;
            border-color: #434343 !important;
            &:checked {
              background: url('../../images/icon--check-w.svg') center center
                no-repeat #434343 !important;
              border-color: #434343 !important;
              background-size: 80% !important;
            }
          }

          &:not(.active) {
            > ul {
              display: none;
            }
          }
        }
      }

      .views-row {
        margin-bottom: 1rem;
      }

      a {
        transition: all 0.3s;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  // Trigger
  .sidebar-trigger {
    @include larger-than($screen-md) {
      display: none;
    }
  }
}
