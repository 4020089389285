/*------------------------------------*\
   Header
\*------------------------------------*/

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 50;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
  @include animated;

  > section {
    @apply tw-w-full;
  }

  &__content {
    @apply tw-py-4;
    background-color: $color-primary;
  }

  .region {
    @apply tw-relative tw-flex tw-items-center;
    flex: 1;

    @include less-than($screen-md) {
      &.region-header-search {
        @apply tw-w-full tw-p-0;
        flex: 0 0 100%;
        > .block {
          width: 100%;
          flex: 0 0 100%;
          padding: 0;
          margin-top: 1rem;
        }
      }
    }

    &.region-header {
      .menu {
        > li {
          padding: 0 10px;
        }
      }
    }
  }

  .logo {
    z-index: 50;
  }

  // Responsive
  @include less-than($screen-lg) {
    .menu {
      > li {
        @apply tw-flex tw-justify-center tw-items-center;
      }
    }
    .logo {
      width: 15rem;
    }
    .region {
      &.region-header {
        @apply tw-mx-12 tw-justify-end;
      }
    }
  }

  /** Responsive **/
  @include larger-than($screen-md) {
    .logo {
      @apply tw-p-0;
    }
    > .tw-container {
      @apply tw-relative tw-items-center;
    }
    .region {
      &.region-header {
        @apply tw-justify-end;
        order: 1;
        flex: 0 0 auto;
        margin-left: auto;
      }
      &.region-header-search {
        order: 0;
      }
    }
  }
}
