/*------------------------------------*\
  Stockist Page
\*------------------------------------*/
html .location-finder-app {
  flex-wrap: wrap;

  ::placeholder {
    color: $color-text;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-text;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-text;
  }

  .search-bar,
  .error-message,
  .search-box,
  .button {
    font-size: 1.4rem;
  }
  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    order: 0;
    flex: 0 0 100%;
  }
  .search-bar,
  .error-message {
    color: #fff !important;
  }
  .search-box {
    padding: 8px 15px;
    border: none;
    color: #000;
    width: 100%;
  }
  .button {
    background-color: $color-tertiary;
    font-weight: normal;
  }
  .error-message {
    padding: 1rem 0;
  }

  /** Responsive **/
  @include less-than($screen-sm) {
    .list-of-locations {
      order: 2;
      width: 100%;
    }
    .all-location.map-container {
      order: 1;
      height: 40rem !important;
      width: 100%;
    }
    .search-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .search-box-container,
    .search-bar,
    .error-message {
      width: 100%;
    }
  }
}
