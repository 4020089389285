/*------------------------------------*\
  Topbar
\*------------------------------------*/
.header {
  &__topbar {
    .region.region-header-topbar {
      display: flex;
      align-items: stretch !important;
      justify-content: flex-end;
      color: #000;
      min-height: 3.5rem;
    }
    .block {
      display: flex;
      align-items: center;
    }
    .top-banner-menu {
      padding-left: 1rem;
      padding-right: 1rem;
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      &::after {
        display: none;
      }
      a {
        &:hover {
          color: $color-secondary;
        }
      }
    }

    /** Responsive **/
    @include less-than($screen-md) {
      .block-koda-multi-currency-display {
        .control-label {
          display: none;
        }
      }
      .top-banner-menu {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .topbar {
      /*
        ======================================================
        Find a stockist
        ======================================================
        */
      &__find-stockist {
        margin-right: auto;
        a {
          &::before,
          &::after {
            font-family: $font-awesome;
          }
          &::before {
            content: '\f3c5';
            margin-right: 1rem;
            font-weight: 800;
            color: $color-primary;
          }
          &::after {
            content: '\f105';
            margin-left: 0.5rem;
            font-weight: bold;
            font-size: 12px;
          }
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
}
