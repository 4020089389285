/*------------------------------------*\
  Video
\*------------------------------------*/
.video {
  &--tabs {
    .view-catalogues {
      .view-content {
        @include larger-than($screen-xxl) {
          gap: 7rem;
        }
      }
    }
  }
}
/** Node Video **/
.node-video {
  /** Full Page **/
  &.view-mode-full {
    .field-name-video-player {
      position: relative;
      width: 100%;
      margin-left: auto;
      margin-bottom: 3rem;
      margin-right: auto;
    }
    .field-name-video-return {
      margin-top: 2rem;
    }
    @include larger-than($screen-lg + 40px) {
      .field-name-video-player {
        width: $screen-lg;
      }
    }
  }
}
