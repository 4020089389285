.page-cart-checkout {
  table {
    &.sticky-header {
      display: none; // Remove this feature
    }

    &.cart-review {
      thead tr {
        border-top: 1px solid #e8e8e8;
      }
      tbody tr {
        border-left: none;
        border-right: none;
      }
    }
  }

  .block-system {
    input,
    select {
      background: #ffffff;
      border: 1px solid #d0d0d0;
      padding: 5px;
      width: 100%;
    }

    .chosen-container-single {
      .chosen-single {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        border-radius: 0;
        background: none;
        box-shadow: none;
        border-color: #d0d0d0;
      }
    }

    /** Fieldset **/
    fieldset {
      margin-bottom: 2rem;
      .panel-heading {
        width: 100%;
        padding: 1rem 2rem;
        margin: 0;
      }
      .panel-body {
        position: relative;
        width: 100%;
        padding: 2rem 0;
      }

      legend {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 1rem;
        background-color: #0d3f6b;
        color: #fff;
      }
    }

    #uc_order_field-pane {
      .control-label {
        width: auto;
        padding-right: 1rem;
      }
    }

    #billing-pane {
      margin-bottom: 0;
      #billing-pane-body {
        margin-bottom: 0;
        .control-label {
          display: flex;
          input[type='checkbox'] {
            flex: 0 0 15px;
            margin-right: 1.5rem;
          }
        }
        .form-item-panes-billing-select-address {
          margin-top: 1rem;
        }
      }
    }

    #billing-address-pane {
      .address-pane-table {
        padding: 2rem 0;
        border-top: 1px solid #d0d0d0;

        table {
          tbody {
            tr {
              border: none;
              display: flex;
              flex-direction: column;
              margin-bottom: 1rem;
              td {
                display: flex;
                padding: 0;
              }
            }
          }
        }

        .chosen-container {
          padding: 0;
          border: none;
          font-size: 1.4rem;
        }
      }
    }

    #delivery-pane {
      .form-item-panes-delivery-select-address {
        display: flex;
        flex-direction: column;
        .control-label {
          text-align: left;
        }
      }
    }

    #delivery-pane-body {
      .form-item-panes-delivery-select-address {
        margin-bottom: 1rem;
        display: flex;
        @include less-than($screen-md) {
          flex-direction: column;
        }
      }
    }

    #delivery-address-pane {
      table {
        width: 100%;
        tr {
          background: unset;
        }
      }

      @include less-than($screen-md) {
        table {
          width: 100%;
          tr,
          thead,
          th,
          tbody,
          td {
            width: 100%;
          }

          td {
            padding: 0;
            &.field-label {
              margin-bottom: 1rem;
              text-align: le;
            }
          }

          tr {
            margin-bottom: 1rem;
          }
        }
      }
    }

    #payment-details {
      margin-top: 2rem;
      width: 100%;
    }

    #comments-pane {
      textarea {
        background: #ffffff;
        border: 1px solid #cccccc;
        height: 90px;
      }
    }

    #quotes-pane {
      .btn {
        font-weight: bold;
      }

      #quote {
        @apply tw-border-none
        tw-p-0;
        label {
          @apply tw-w-full
          tw-flex
          tw-py-2
          tw-items-center
          tw-justify-start;
          input {
            @apply tw-w-auto 
            tw-mx-4;
          }
        }
      }
    }

    .form-actions {
      flex-direction: column;
      justify-content: center;

      button {
        @extend .button;
        @extend .button-square;
        margin-bottom: 1rem;
      }

      #edit-cancel {
        @extend .button-primary;
      }

      #edit-continue {
        @extend .button-success;
      }

      @include larger-than($screen-sm) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .help-block {
    margin-bottom: 1.5rem;
  }

  /*
  ======================================================
  Card Checkout form
  ======================================================
  */
  form {
    &.uc-cart-checkout-form {
      position: relative;
      width: 100%;

      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        > * {
          margin: 0;
          width: 100%;
        }
      }

      legend {
        font-weight: normal;
        line-height: 1;
      }

      #cart-pane {
        legend > span {
          font-size: 0;
          &::before {
            content: 'Review your Order';
            font-size: 1.6rem;
          }
        }
        .panel-body {
          background-color: #fafafa;
          padding: 2rem;
        }
        .uc-price {
          font-weight: normal;
        }

        table {
          margin-top: 0;
          font-size: 1.4rem;
          thead {
            background: unset !important;
            th {
              color: #3f3f3f !important;
            }
          }
          tr {
            //border-top: 1px dotted #cacaca !important;
            td {
              padding: 1rem;
              font-size: 1.4rem;
              &.products {
                text-transform: uppercase;
              }
            }
          }
          // td.subtotal {
          //   border-top: 1px dotted #cacaca !important;
          //   border-bottom: 1px dotted #cacaca !important;
          // }
        }
      }

      /** Coupon **/
      #coupon-pane {
        legend,
        .help-block,
        .form-item .control-label {
          display: none;
        }
        .panel-body {
          padding: 0;
          display: flex;
        }

        .form-item {
          display: flex;
          margin-top: 0;
          margin-bottom: 0;
          height: 100%;
          input {
            height: 100%;
            padding: 0.5rem 1.5rem;
          }
        }

        #edit-panes-coupon-apply {
          min-width: auto;
          margin-left: 1rem;
          padding-top: 0;
          padding-bottom: 0;
          background-color: #3f3f3f;
          border-color: #3f3f3f;
        }
      }

      /** Customer Pane **/
      #customer-pane {
        margin-bottom: 0;
      }
      #customer-pane-body {
        margin-bottom: 0;
        .help-block {
          margin-bottom: 1.5rem;
        }
      }
      #uc_order_field-pane {
        border-bottom: 1px solid #d0d0d0;
        .panel-body {
          .help-block {
            margin-bottom: 0;
          }
        }
        label {
          font-weight: 700;
        }
        .form-control {
          margin: 0.5rem 0;
        }
      }

      /** Customer Pane **/
      #delivery-pane {
        .help-block,
        .panel-heading {
          display: none;
        }
        .panel-body {
          padding-top: 0;
        }
        .field-label {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          font-weight: 500;
          padding-bottom: 0;
        }
        .address-pane-table {
          table > tbody {
            display: flex;
            flex-wrap: wrap;
          }
          tr {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 1rem;
            border: none;

            &.field-delivery_first_name {
              order: 0;
            }
            &.field-delivery_last_name {
              order: 1;
            }
            &.field-delivery_phone {
              order: 2;
            }
            &.field-delivery_company {
              order: 3;
            }
            &.field-delivery_street1,
            &.field-delivery_street2 {
              order: 4;
            }
            &.field-delivery_ucxf_cf_region {
              order: 5;
            }
            &.field-delivery_postal_code {
              order: 6;
            }
            &.field-delivery_country {
              order: 7;
              .form-select {
                height: 3.9rem;
              }
            }
            > td {
              padding: 0;
            }
            @include larger-than($screen-md) {
              &.field-delivery_first_name {
                width: 50%;
                padding-right: 1rem;
              }
              &.field-delivery_last_name {
                width: 50%;
                padding-left: 1rem;
              }
              &.field-delivery_ucxf_cf_region {
                width: 50%;
                padding-right: 1rem;
              }
              &.field-delivery_postal_code {
                width: 50%;
                padding-left: 1rem;
              }
            }
          }
        }

        .chosen-container {
          border: 1px solid #d0d0d0;
          height: 3.9rem;
        }

        .chosen-single {
          height: 100%;
          padding: 3px 6px;
          font-size: 1.4rem;
          background: none;
          border: none;
          div {
            top: 4px;
          }
        }

        .field-delivery_ucxf_leave_goods {
          display: none;
        }
      }

      /** Delivery Pane **/
      #delivery-address-pane {
        @include less-than($screen-md) {
          table {
            margin-top: 0;
            tr {
              display: flex;
              flex-direction: column;
              //width: auto !important;
              > td {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                &.field-label {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }

      /** Actions **/
      #edit-actions {
        display: flex;
        flex-direction: column;
        #edit-continue {
          order: 0;
        }
        #edit-cancel {
          order: 1;
          background: unset;
          border: none;
          color: $color-primary;
        }
      }

      /** Responsive **/
      @include larger-than($screen-lg) {
        > div {
          display: grid;
          gap: 4rem;
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }

  /*
  ======================================================
   Wrap JS - Generated from js checkoutForms()
  ======================================================
  */
  .wrap-js {
    display: flex;
    flex-direction: column;
  }
  /** Custom Order requested from design **/
  #payment-pane {
    order: 2;
  }
  #uc_checkout_extra-pane {
    order: 3;
  }
  #edit-actions {
    order: 4;
  }
}
/*
======================================================
 Extra Pane
======================================================
*/
#uc_checkout_extra-pane {
  .form-item {
    > label {
      display: inline;
      input[type='checkbox'] {
        width: auto;
        margin: 0.5rem 1rem 0 0;
      }
    }
  }
}

/*
======================================================
 Payment Page
======================================================
*/
#payment-pane {
  position: relative;
  /*
  ======================================================
  Order total preview
  ======================================================
  */
  #uc-order-total-preview {
    border: none;
    width: 100%;
    tr {
      width: 100%;
      background: unset;
      td {
        &:first-child {
          text-align: start;
        }
      }
    }
  }

  #line-items-div {
    position: relative;
    width: 100%;
    border: none;
  }

  .form-item.radio {
    label {
      display: flex;
      align-items: center;
      width: 100%;

      input[type='radio'] {
        width: 30px;
        min-width: 30px;
      }
    }
  }
}

/*------------------------------------*\
  Checkout Review Page
\*------------------------------------*/
#review-instructions {
  margin-bottom: 2rem;
}

.order-review-table {
  .pane-title-row {
    background-color: #fafafa;
    border-color: #eaeaea;
    td {
      font-weight: bold !important;
      font-weight: 1.6rem;
      .title-col {
        font-weight: bold;
      }
    }
  }

  @include less-than($screen-md) {
    > tbody {
      > tr {
        > td {
          line-height: 1.5;
        }
      }
    }
    .table-responsive {
      width: 80vw;
      margin: 0 auto;
      border: 1px solid #eaeaea;
    }
  }

  .field-name-field-purchase-order-number {
    display: flex;
    justify-content: center;
  }
  .form-actions {
    @include larger-than($screen-md) {
      button {
        &:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
  }
}
