/*------------------------------------*\
  Header Locality
\*------------------------------------*/
.header-locality {
  padding-left: 1rem;
  form {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .form-item {
        width: auto;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        label {
          padding-right: 0.5rem;
        }
        &.form-item-country-select {
          .chosen-container {
            width: 12.5rem !important;
          }
        }
        &.form-item-currency-select {
          .chosen-container {
            width: 7rem !important;
          }
        }

        .chosen-container {
          .chosen-single {
            display: flex;
            font-size: 11px;
            span {
              margin-right: 8px;
            }
          }
          .chosen-results {
            font-size: 11px;
          }
        }
      }
    }
  }
}
