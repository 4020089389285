/*------------------------------------*\
  Banner
\*------------------------------------*/
.banner {
  display: none;
  img {
    width: 100%;
    max-height: 26.3rem;
    height: auto;
    object-fit: cover;
    object-position: bottom right;
  }
  /** Responsive **/
  @include larger-than($screen-md) {
    display: block;
    img {
      height: 14rem;
    }
  }
  @include larger-than($screen-xl) {
    img {
      height: 26.3rem;
    }
  }
}
