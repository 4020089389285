/*------------------------------------*\
    Component: Button
\*------------------------------------*/
@mixin button() {
  * {
    border-radius: 85px;
    background: #9b9b9b;
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 8px 20px;
    transition: background 0.3s;
    min-width: 13.2rem;
    align-items: center;

    &:hover {
      background-color: lighten(#9b9b9b, 10%);
    }
  }
}

@mixin button-color($color) {
  background: $color;
  border-color: $color;

  &:hover {
    background-color: lighten($color, 10%);
  }
}

.button {
  @include button;
}

.button-primary {
  @include button-color($color-primary);
}

.button-success {
  @include button-color($color-green);
}

.button-square {
  border-radius: 3px;
}

.button-default {
  @include button-color(#000);
}
