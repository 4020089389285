/*------------------------------------*\
  File: News
\*------------------------------------*/

.news-teaser {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  &__image {
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 2rem;
    @include imgZoomHover;

    a {
      width: 100%;
      img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    h2 {
      font-size: 1.8rem;
      color: #000;
      font-weight: 600;
      letter-spacing: 0.05rem;
      a {
        &:hover {
          color: $color-orange;
        }
      }
    }
    .field-name-node-link {
      margin-top: auto;
      color: $color-orange;
      padding: 1rem 0;
      a {
        font-weight: 600;
        letter-spacing: 0.05rem;
        &::after {
          content: '\f105';
          font-weight: inherit;
          margin-left: 0.5rem;
          font-size: 10px;
          font-family: $font-awesome;
        }
      }
    }
  }
}

.news-content {
  @apply tw-w-full tw-flex tw-flex-col tw-gap-8;
  .field-name-body .field-item {
    p {
      &:last-child {
        @apply tw-mb-0;
      }
    }
  }
  &__images {
    @extend .gallery-tile;
  }
}

/** News Page **/
.view-news {
  .view-filters {
    .views-exposed-widgets {
      margin-bottom: 2rem;
      display: flex;
      .views-exposed-widget {
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: 1rem;
        .form-item {
          margin: 0;
        }
        label {
          margin-right: 1rem;
        }
        select {
          padding: 0.8rem 1rem;
          border: 1px solid $color-light-grey;
        }
        &.views-submit-button {
          padding: 0;
        }
      }
    }
    @include less-than($screen-md) {
      .views-exposed-widgets {
        flex-direction: column;
        margin-bottom: 0;
        .views-widget,
        .form-item,
        .date-padding {
          width: 100%;
        }
        .views-exposed-widget {
          margin-bottom: 1rem;
          flex-direction: column;
          align-items: flex-start;
          label {
            margin: 0;
          }
        }
      }
    }
  }
}
