.page-products,
.page-taxonomy-term,
.page-products-search,
.products {
  main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    @include less-than($screen-sm) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .main-content {
    flex: 1;
    @include less-than($screen-sm) {
      width: 100%;
      padding-left: 0 !important;
    }
    // Sidebar
    .region-content-left {
      @include animated;
      color: #353535;

      .sidebar-trigger {
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 10px;
        font-size: 14px;
        z-index: 55;
        height: 100%;
        cursor: pointer;

        > a {
          @include animated;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-tertiary;
          color: #fff;
          padding: 0.2rem;
          transform: rotate(-90deg);
          position: absolute;
          width: 140px;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: normal;
          text-align: center;

          &::after {
            content: '\f067';
            font-family: $font-awesome;
            margin-left: 1rem;
            font-weight: 100;
            @include animated;
          }
        }
      }

      // Opened
      &.opened {
        .sidebar-filter {
          transform: translateX(0);
        }
        .sidebar-trigger > a {
          background-color: $color-secondary;
          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      .sidebar-filter {
        input {
          border: 1px solid #e6e6e6;
        }
        ul {
          li {
            color: #353535;
          }
          > li {
            margin-top: 1rem;
            &.expanded {
              > ul {
                padding-left: 2rem;
              }
            }
          }
        }
      }

      // Block
      .block {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
        .block-title {
          font-size: $base-font-size;
        }
      }

      // Responsive
      @include less-than($screen-md) {
        .sidebar-filter {
          @include animated;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 50;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 2rem 4rem;
          background-color: #fff;
          justify-content: center;
          align-items: center;
          overflow-y: scroll;
          overflow-x: hidden;
          transform: translateX(-100%);
        }
      }
      @include larger-than($screen-md) {
        position: relative;
        flex: 0 0 350px;
        padding: 0 2rem 0 0;
        .sidebar-trigger {
          display: none;
        }
      }
    }

    // Main Content
    .region-content {
      display: flex;
      flex-direction: column;

      .block-search-api-sorts {
        order: -1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        h2 {
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 0;

          &:after {
            content: ':';
          }
        }
        select {
          background: #ffffff;
          margin-left: 2rem;
          border: 1px solid #acacac;
          box-sizing: border-box;
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 0.02em;
          color: #000000;
          padding: 4px 1rem;
        }
      }
      /*------------------------------------*\
        View
      \*------------------------------------*/
      .view-category-products-node-listing,
      .view-category-products-listing,
      .view-product-and-product-collection-search-api {
        .view-filters {
          margin-bottom: 2rem;
          #edit-search-api-views-fulltext-wrapper {
            display: none;
          }
          .views-exposed-form .views-exposed-widget {
            padding: 0;
          }
          .views-exposed-widgets {
            #edit-keyword-wrapper {
              display: none;
            }
          }
          .views-exposed-widgets {
            display: flex;
            justify-content: flex-end;
            .views-widget-sort-by,
            .form-item-sort-by,
            .form-item-sort-bef-combine {
              display: flex;
              padding: 0;
              > label {
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                padding-right: 1rem;
                font-size: 1.5rem;
              }
              .form-control {
                border: 1px solid #e2e8f0;
                padding: 0.5rem;
              }
            }
          }
        }
      }

      /** Sidebar Search **/
      .view-product-and-product-collection-search-api {
        .view-filters {
          .views-exposed-widgets {
            display: flex;
            justify-content: flex-start;
            .form-type-select {
              border: 1px solid #d4d4d4;
              padding-left: 1rem;
              padding-right: 1rem;
              label {
                font-weight: normal;
                padding: 0;
                font-size: $base-font-size;
              }
              select.form-control {
                border: none !important;
                box-shadow: none !important;
                padding-left: 0;
                font-size: $base-font-size;
              }
            }
          }
        }
      }

      .view-category-products-listing {
        @extend .view-five-columns;
      }
    }
  }
}

/** Sidebar **/
.sidebar-filter {
  background-color: #f3f3f3;
  padding: 2rem;
  width: 100%;
}
