/*------------------------------------*\
  Catalogues
\*------------------------------------*/
.catalogues {
  &--tabs {
    .view-catalogues {
      .view-content {
        @include larger-than($screen-xxl) {
          gap: 7rem;
        }
      }
    }
  }
}
