@mixin container {
  position: relative;
  max-width: 92%;
  margin-left: auto;
  margin-right: auto;

  @include larger-than($screen-xxl + 40px) {
    max-width: $screen-xxl;
  }

  @include less-than($screen-xxl) {
    max-width: $screen-xl;
  }

  @include less-than($screen-xl + 40px) {
    width: 100%;
    padding: 0 2rem;
  }
}

.tw-container {
  @include container;
}

.sidebar-first {
  .content {
    .sidebar {
      display: block;
    }

    @include larger-than($screen-md) {
      .main-content {
        padding-left: 3rem;
      }
    }
  }
}

main {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content {
  .content-wrapper {
    display: flex;
    justify-content: center;
    .region-content {
      width: 100%;
    }

    .region-content-right {
      flex-basis: 25%;
      width: 25%;
      padding-left: 4rem;
    }

    @include less-than($screen-md) {
      flex-direction: column;

      .region-content-right {
        flex-basis: 100%;
        width: 100%;
        padding-left: 0;
        margin-top: 5rem;
      }
    }
  }

  @include less-than($screen-sm) {
    margin: 0;
  }

  .breadcrumb {
    margin: 1rem 0 0.5rem;
  }

  .sidebar {
    display: none;
    .view-footer-category-links {
      display: none;
    }
  }

  // Page Loading
  &#content-block {
    position: relative;
    &.page-loading {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 80;
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
