/*------------------------------------*\
  UC Cart 
\*------------------------------------*/
.block-uc-ajax-cart-alt {
  display: none; // Hack to avoid loading
}

.block-uc-ajax-cart-alt.block-uc-cart {
  @extend .logged-in-only;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 5px;

  h2.block-title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $base-font-size;
    margin-bottom: 0 !important;
    color: #fff;
    > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &::before {
        content: '';
        @include headerIcon(#054781, 'icon--header-cart.svg');
        @include animated;
      }
      &:hover {
        &::before {
          background-color: darken(#054781, 5%);
        }
      }
      &::after {
        content: 'Cart';
      }
    }
  }

  .cart-block-summary {
    position: absolute;
    top: 0;
    margin: 4px 0 0 20%;
  }
  .cart-block-title-bar,
  .cart-block-items,
  .cart-block-summary-links,
  .cart-block-summary-total,
  .block-title a span {
    display: none;
  }

  .cart-block-summary-items {
    color: transparent;
    font-size: 0;
  }

  .num-items {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #fff;
    background-color: #f39b15;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    font-size: 12px;
  }

  // Responsive
  @include less-than($screen-lg) {
    .block-title {
      > a {
        background-color: transparent; /** Hack **/
        display: block;
        padding-right: 0;
        margin-right: -1rem;
        &::before {
          content: '';
          background: url('../../images/icon--cart.svg') center center no-repeat;
          display: inline-block;
          width: 30px;
          height: 30px;
        }
      }
    }

    .cart-block-summary-items {
      background-color: green;
    }
  }
}
