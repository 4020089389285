.view-subcategory-terms-and-products {
  .view-content {
    margin-bottom: 4rem;
    .views-row {
      .product-teaser {
        position: relative;
        width: 100%;
        padding-bottom: 25px;

        &__images {
          a {
            z-index: 10;
          }
          .category-inline-bg-colour {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 5;
            opacity: 100 !important;
          }
        }
        .category-inline-colour {
          position: absolute;
          width: 100%;
          height: 5px;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

.vocabulary-category {
  height: 100%;
  @include product-teaser;

  .product-teaser {
    border-radius: 0;
    box-shadow: 0.844888px 0.844888px 4.22444px rgba(0, 0, 0, 0.15);
  }
}
