/*------------------------------------*\
  Hamburger
\*------------------------------------*/
.hamburger {
  @apply tw-absolute
  tw-relative 
  tw-cursor-pointer 
  tw-mx-4
  tw-ml-auto
  tw-mr-0
  tw-right-0
  tw-top-0;
  height: 30px;
  width: 30px;

  @include larger-than($screen-md) {
    @apply tw-hidden;
  }

  span {
    background: #fff;
    display: block;
    height: 4px;
    margin: 5px 0 0 5px;
    border-radius: 0;
    width: 30px;
    position: relative;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:last-child {
      transform-origin: 0% 100%;
    }
  }

  &.is-active span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}

html.mm-opening .header {
  .hamburger span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #fff;
    cursor: pointer;

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}

/*------------------------------------*\
  Slide Menu
\*------------------------------------*/
.slide_menu {
  @apply tw-hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #0052a0;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateX(-100%);
  overflow: auto;
  color: inherit;
  font-size: 18px;

  &--site-title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 2rem;
    position: sticky;
    border-bottom: $slide-menu-border;

    .logo {
      padding: 0;
    }
  }

  .mobile-navigation {
    padding: 0.5rem 2rem;

    a {
      font-size: inherit;
    }

    &--custom-menu {
      border-top: $slide-menu-border;
    }

    #app-custom-menu--mobile {
      padding-left: 1rem;
      display: none;

      &.active {
        display: block;
      }
    }

    .header-locality {
      padding-left: 0;
      padding: 0 1rem;
      border: $slide-menu-border;

      form > div {
        gap: 0;
        flex-direction: column;
        color: #fff;

        .form-item {
          padding: 4px 0;

          &:not(:first-child) {
            border-top: $slide-menu-border;
          }

          select {
            border: none;
            color: #fff;
            > option {
              color: #000;
            }
          }

          .chosen-single > span.fi,
          > span.fi {
            display: none;
          }

          label {
            &.control-label {
              font-size: 0;

              &::before {
                font-family: $font-awesome;
                font-size: 2rem;
                font-weight: 300;
                color: $color-tertiary;
                display: flex;
                width: 30px;
                justify-content: center;
              }

              &[for*='country-select'] {
                &::before {
                  content: '\f3c5';
                }
              }

              &[for*='currency-select'] {
                &::before {
                  content: '\f3d1';
                }
              }
            }
          }
        }

        .chosen-container-single {
          width: 100% !important;

          .chosen-single {
            display: inline-flex;
            box-shadow: none !important;
            background: none !important;
            border: none !important;
            color: #fff;
            font-size: $base-font-size;

            &::after {
              margin-left: 4px;
              content: '\f107';
              font-family: $font-awesome;
              color: $color-tertiary;
            }
          }
          .chosen-drop {
            color: #000;
          }
        }
      }
    }

    // Mobile Menu
    .region-mobile-menu {
      ul.menu {
        li {
          border-top: $slide-menu-border;
          position: relative;
          display: flex;

          > a {
            width: auto;
          }

          &.dropdown {
            flex-wrap: wrap;

            .expand-menu {
              width: 30px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 0;
              outline: none;
              z-index: 10;
              order: 1;
              margin-left: 1rem;

              &:active,
              &:hover,
              &:focus {
                outline: none;
                background-color: unset;
              }

              &::before {
                @include animated;
                content: '\f107';
                font-size: 1.5rem;
                font-weight: normal;
                font-family: $font-awesome;
                color: #ff5308;
              }
            }

            // Open
            &.open {
              > .expand-menu {
                &::before {
                  content: '\f106';
                }
              }

              > .dropdown-menu {
                order: 2;
                visibility: visible;
                display: flex;
              }
            }
          }

          .dropdown-menu {
            position: relative;
            display: none;
            height: 100%;
            width: 100%;
            padding: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }

  a {
    display: inline-flex;
    color: $color-primary;
    font-size: 2rem;
    padding: 0.8rem 0;
    width: auto;
    color: #fff;
  }

  .products-menu {
    &::after {
      content: '\f107';
      font-family: $font-awesome;
      color: $color-tertiary;
      margin-left: 1rem;
    }
  }

  @include less-than($screen-md) {
    display: block;
    .mega-menu {
      &.top-menu {
        display: flex !important;
      }
    }
  }

  /** Slide Menu **/
  .mega-menu {
    &__list {
      &.top-menu {
        color: $color-primary;
        display: block;
        background: unset;
      }
    }
    &__list-item {
      color: $color-primary;
      text-align: left;
      background: inherit;
      border-top: $slide-menu-border;

      .submenu-indicator {
        margin-left: 1rem;
        font-style: normal;
        &::before {
          color: $color-tertiary;
          font-family: $font-awesome;
          content: '\f107';
        }
      }

      &.active {
        > a {
          color: $color-tertiary;
          .submenu-indicator {
            &::before {
              color: #c4c4c4;
              content: '\f106';
            }
          }
        }
      }

      > ul.mega-menu__list {
        padding-left: 1rem;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .region-slide-menu {
    position: relative;
  }

  .slide_menu_trigger {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: #b4b4b4;
    transition: all 0.3s;
    font-size: 2rem;
  }

  .block-title {
    display: none;
  }

  .menu-block-wrapper {
    .menu {
      display: flex;
      flex-direction: column;
      > li {
        text-align: left;
        &.dropdown {
          > a {
            display: flex;
            align-items: center;
            .caret {
              margin-left: 1rem;
              display: inline-block;
              transition: all 0.3s;
              &:before {
                content: '\f107';
                font-family: 'fontawesome';
                font-size: 25px;
              }
            }
          }
        }

        &.opened {
          .caret {
            transform: rotate(180deg);
          }
        }

        a {
          display: inline-block;
          width: 100%;
          transition: all 0.3s;

          &:hover {
            color: $color-primary;
          }
        }

        ul.dropdown-menu {
          margin-left: 2rem;
          padding-bottom: 2rem;
          display: none;
        }
      }
    }
  }
}

/*------------------------------------*\
  Slide Menu - Opened
\*------------------------------------*/
.slide_menu-opened {
  .slide_menu {
    transform: translateX(0);
    overflow-y: scroll;
  }

  .slide_menu_layer {
    @include less-than($screen-md) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.slide_menu_layer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
}
