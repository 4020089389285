/*------------------------------------*\
  Header Options
\*------------------------------------*/
.header-options {
  color: #fff;
  ul.menu {
    > li {
      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        &::before {
          content: '';
          @include animated;
        }
      }
    }
  }
}

/*
======================================================
 Header Mobile Search
======================================================
*/
.header-mobile-search {
  @include larger-than($screen-md) {
    display: none;
  }
  > a {
    &:hover {
      &::before {
        background-color: darken(#054781, 5%);
      }
    }
    &::before {
      @include headerIcon(#054781, 'icon--search.svg');
    }
  }
}
/*
======================================================
 Header Catalogues
======================================================
*/
.header-catalogues {
  > a {
    &:hover {
      &::before {
        background-color: darken(#054781, 5%);
      }
    }
    &::before {
      @include headerIcon(#054781, 'icon--catalogues.svg');
    }
  }
}
/*
======================================================
 Header Favourites
======================================================
*/
.header-favourites {
  > a {
    &:hover {
      &::before {
        background-color: darken(#054781, 5%);
      }
    }
    &::before {
      @include headerFontIcon(#054781, '\f004', #fff);
    }
  }
}
/*
======================================================
 Header Dashboard
======================================================
*/
.header-dashboard {
  > a {
    &:hover {
      &::before {
        background-color: darken(#054781, 5%);
      }
    }
    &::before {
      @include headerFontIcon(#054781, '\f009', #fff);
    }
  }
}
/*
======================================================
 Header User
======================================================
*/
.header-user {
  color: #fff;

  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      &::before {
        background-color: darken(#054781, 5%);
      }
    }
    &::before {
      content: '';
      @include animated;
      @include headerIcon(#054781, 'icon--account.svg');
    }
  }

  .dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: absolute;
    z-index: 80;
    left: auto;
    right: 0;
    max-width: unset !important;
    min-width: 100px !important;
    top: 100%;
    > li {
      padding: 0;
      > a {
        padding: 0.5rem 1rem !important;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
