/*------------------------------------*\
  Quick Tabs
\*------------------------------------*/

ul.quicktabs-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 4rem;
  width: 100%;

  > li {
    border-bottom: 2px solid transparent;
    font-size: 1.4rem;
    color: #000;

    &.active {
      color: $color-orange;
      border-bottom-color: $color-orange;
    }

    a {
      display: flex;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1rem;
    }
  }

  @include larger-than($screen-md) {
    width: max-content;
    justify-content: flex-start;
    > li {
      font-size: 1.8rem;
      &:not(:first-child) {
        margin-left: 2rem;
      }
      a {
        padding: 0;
      }
    }
  }
}
