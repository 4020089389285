/*------------------------------------*\
  Form
\*------------------------------------*/
.form {
  &--request-quote {
    @apply tw-w-full tw-py-8;
    .block-title {
      @apply tw-font-bold;
    }
  }
}
