/*------------------------------------*\
  Homepage
\*------------------------------------*/

.front {
  .region.region-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 2.5rem;
  }
}

.home {
  #content-block {
    margin-top: 4rem;
  }

  .block-title {
    width: 100%;
    text-align: center;
  }
  /*
    ======================================================
     Featured Tile
    ======================================================
    */

  &__featured-tile {
    width: 100%;
    padding: 3rem;
    img {
      margin: 0 auto;
      height: 21.5rem;
      object-fit: contain;
    }
    h3 {
      font-size: 2.2rem;
      font-weight: 600;
    }
    .learn-more {
      color: $color-orange;
      font-weight: 600;
      &::after {
        font-weight: inherit;
        margin-left: 0.5rem;
        font-size: 1.2rem;
        content: '\f105';
        font-family: $font-awesome;
      }
    }

    @include larger-than($screen-md) {
      width: 50%;
    }
    @include larger-than($screen-lg) {
      width: 25%;
      &:nth-child(even) {
        background-color: #f3f3f3;
      }
    }
  }

  /*
======================================================
  Block: Home Featured Content
======================================================
*/

  &__featured-content {
    @apply tw-w-full tw-mt-16;
  }

  /*
  ======================================================
    Block: Home News
  ======================================================
  */
  &__news {
    .block-title {
      @apply tw-py-16 tw-mb-0 tw-text-center;
      font-weight: bold;
      color: #000;
    }
  }
}
