/*------------------------------------*\
  Product Collection
\*------------------------------------*/
.node-product-collection {
  position: relative;
  width: 100%;

  &.node {
    @include product-teaser;
  }
}

.field-name-field-products {
  > .field-items {
    display: grid;
    gap: 4rem;

    @include larger-than($screen-md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include larger-than($screen-lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @include larger-than($screen-xl) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
}

/*
======================================================
 Product Collection Table
======================================================
*/

.product-collection-table {
  padding: 1.5rem 0;
  overflow-x: auto;
  width: 100%;
  min-width: 300px;
  font-size: $base-font-size;

  .uc-price {
    font-weight: 400;
    font-size: $base-font-size;
  }

  .btn {
    min-width: auto;
    font-weight: normal !important;
    @extend .btn-tertiary;
  }

  .product-collection-table-row > form > div,
  .product-collection-table-header-row {
    display: flex;

    > div {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      text-align: center;
      color: #1a1a1a;

      &.table-header-sku,
      &.product-collection-row-sku {
        flex: 1;
      }

      &.table-header-description,
      &.product-collection-row-short-desc {
        flex: 1;
      }

      &.table-header-price,
      &.product-collection-row-price {
        flex: 1;
      }

      &.table-header-qty-breaks,
      &.product-collection-row-qty-breaks {
        flex: 1;
      }

      &.form-type-checkbox,
      &.table-header-quick-order {
        flex: 1;
      }

      &.form-item-qty,
      &.table-header-quantity {
        flex: 1;
      }

      &.form-actions,
      &.table-header-order {
        flex: 1;
      }
    }
  }

  .product-collection-table-header-row {
    > div {
      background: $color-secondary;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      line-height: 1.2;
      color: #ffffff;
      padding: 1rem 5px;
    }
  }

  .product-collection-table-row {
    &:nth-child(even) > form > div > div {
      background: #f1f8ff;
    }

    > form {
      > div {
        > div {
          min-height: 44px;
          padding: 3px 5px;
          margin: 0;
          border: 1px solid #d4d4d4;
        }

        .product-collection-row-short-desc {
          button {
            background: none;
            border: none;
            height: 100%;
          }
        }

        .product-collection-row-qty-breaks {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 10px 24px;
          > p {
            font-weight: 500;
          }
        }
        .form-type-checkbox {
          font-size: 0;

          label.control-label {
            width: 24px;
            height: 24px;
            @include custom-checkbox('box');
          }
        }
        .form-item-qty {
          color: #1a1a1a;
          label.control-label {
            display: none;
          }
          input.form-control[type='number'] {
            width: 33px;
            height: 22px;
            padding: 0;
            text-align: center;
            -moz-appearance: textfield;
            background: #ffffff;
            border: 1px solid #dedede;
            box-sizing: border-box;
            border-radius: 0;

            @include less-than($screen-sm) {
              width: 20px;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
          }

          .product-minus,
          .product-plus {
            border: 1px solid #dedede;
            box-sizing: border-box;
            border-radius: 0;
            background: #dedede;
            height: 22px;
            padding: 0 0.5rem;
          }
        }
        .form-actions {
          position: relative;
          button {
            //font-size: 13px;
            font-weight: 700;
            padding: 0.4rem 2rem;
            .icon {
              display: none;
            }
          }
          .uc-ajax-cart-alt-status-messages {
            .alert {
              animation: 1s fadeOut;
              animation-delay: 1s;
              animation-fill-mode: forwards;
              position: absolute;
              bottom: 35px;
              left: 0;
              > strong {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
