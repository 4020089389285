/*------------------------------------*\
  Home navigation options
\*------------------------------------*/

.home-navigation-options {
  @include extendbg(#e9e9e9);
  @apply tw-relative;

  .menu {
    @apply tw-flex tw-flex-wrap tw-py-4;

    > li {
      @apply tw-relative tw-flex tw-flex-col tw-justify-center tw-items-center;
      flex: 0 0 50%;

      > a {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.8rem;
        @apply tw-w-full tw-flex tw-items-center tw-px-4;
        &:hover {
          color: $color-tertiary;
        }
        &::before {
          @apply tw-mr-4;
        }
      }
    }
  }

  /** Responsive **/

  @include larger-than($screen-sm) {
    .menu {
      > li {
        flex: 0 0 33.3%;
        border: none;
      }
    }
  }

  @include larger-than($screen-lg) {
    .menu {
      > li {
        flex: 1;
        @apply tw-flex-row tw-justify-start;
        &:first-child {
          > a {
            @apply tw-pl-4;
          }
        }
        &:not(:first-child) {
          border-left: 1px dashed #c4c4c4;
          > a {
            @apply tw-px-4;
          }
        }
      }
    }
  }

  @include larger-than($screen-xl) {
    .menu {
      > li {
        > a {
          @apply tw-text-left;
          font-size: 1.5rem;
        }
      }
    }
  }

  /*
    ======================================================
     Icons
    ======================================================
    */
  .distributing-worldwide a {
    &::before {
      content: url('../../images/icon--dis-ww.svg');
    }
  }
  .ask-your-neighbour a {
    &::before {
      content: url('../../images/icon--ask-neighbour.svg');
    }
  }
  .nz-owned a {
    &::before {
      content: url('../../images/icon--nz-owned.svg');
    }
  }
  .supporting-communities a {
    &::before {
      content: url('../../images/icon--support-com.svg');
    }
  }
  .satisfaction-guaranteed a {
    &::before {
      content: url('../../images/icon--sat-guarantee.svg');
    }
  }
  .hansen-approvals a {
    &::before {
      content: url('../../images/icon--hansen-approvals.svg');
    }
  }
}
