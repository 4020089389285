/*------------------------------------*\
  Product Page
\*------------------------------------*/
.node-product-collection,
.node-product {
  position: relative;
  width: 100%;
  &.node {
    @include product-teaser;
  }
  .display-price-prefix {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  /*
  ======================================================
    Best Seller
  ======================================================
  */

  .field-name-best-seller-tag {
    .field-items {
      display: flex;
      .field-item {
        background-color: $color-green;
        color: #fff;
        font-size: 10px;
        border-radius: 5px;
        padding: 0.8rem 1.5rem;
        font-weight: bold;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }
  }

  /*
    ======================================================
    Product Enquiry Form
    ======================================================
    */
  .product-enquiry-form {
    h2.block-title {
      color: #000;
      font-size: 1.8rem;
    }
    .form-actions {
      display: flex;
      justify-content: flex-end;
      .btn {
        @extend .btn-tertiary;
        text-transform: uppercase;
      }
    }
    @include larger-than($screen-md) {
      width: 70%;
    }
  }

  /*
    ======================================================
    FAQ
    ======================================================
    */
  .view-product-faq {
    .view-content {
      > .views-row {
        border-bottom: 1px dashed #dfdfdf;
        &:first-child {
          border-top: 1px dashed #dfdfdf;
        }
      }
    }
  }

  /*
    ======================================================
     Full Page
    ======================================================
    */
  &.view-mode-full {
    .product {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      /** Image **/
      &__image {
        width: calc(100vw - 4rem);
        margin-bottom: 2rem;

        .media--image {
          display: flex;
          justify-content: center;

          @include larger-than($screen-sm) {
            img {
              cursor: url('../../images/icon--magnifier.svg'), auto;
            }
          }

          @include less-than($screen-sm) {
            img {
              height: 300px;
              object-fit: contain;
            }
          }
        }

        /** Slick **/
        .slick-wrapper {
          display: block;
          position: relative;
          width: 100%;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: contain;
            background-color: #fff;
            &:first-child {
              max-height: 80rem;
            }
          }

          .slick--main {
            .slick__arrow {
              .slick-arrow {
                font-size: 4rem;
              }
            }
          }
          .slick--thumbnail {
            position: relative;
            margin-top: 2rem;
            margin-bottom: 2rem;
            display: none;
            .slick__arrow {
              .slick-arrow {
                font-size: 2rem;
              }
            }
            .slick-slide {
              cursor: pointer;
              margin-bottom: 4px;
              img {
                cursor: pointer;
                border: 1px solid #d8d8d8;
              }
            }
          }
          .slick__arrow {
            .slick-arrow {
              font-weight: normal;
              &::before {
                padding: 1rem;
                font-size: 2rem;
                background-color: rgba(11, 23, 39, 0.5) !important;
                color: #fff !important;
                @include animated;
              }
              &:hover {
                &::before {
                  background-color: rgba(11, 23, 39, 0.4);
                  color: $color-primary;
                }
              }
            }
          }
        }

        .product-image {
          padding: 0 !important;
          margin: 0 !important;
        }

        .field-name-field-short-description {
          border-top: 1px solid #d8d8d8;
          padding-top: 2.5rem;
          margin-top: 1rem;
          font-style: italic;
        }
      }

      /*
            ======================================================
             Labels
            ======================================================
            */
      &__labels {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        > div {
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
      }

      /*
            ======================================================
             Description
            ======================================================
            */
      &__description {
        width: 100%;
        display: flex;
        flex-direction: column;

        .field-name-title {
          h2 {
            font-size: 3rem;
          }
        }
        .field-name-body {
          margin-top: 1rem;
          width: 100%;
        }
        .field-name-field-features-benefits {
          width: 100%;
        }

        .field-name-field-product-brochure {
          margin-left: auto;
          .file {
            .file-icon,
            .file-size {
              display: none;
            }
            a {
              display: flex;
              color: #ff5308;
              padding: 0.5rem 1rem;
              border-radius: 6px;
              border: 1px solid $color-tertiary;
              font-size: 0;
              &::before {
                content: 'Download Brochure (PDF)';
                font-size: 14px;
              }
              &::after {
                font-family: $font-awesome;
                content: '\f33d';
                font-size: 14px;
                margin-left: 0.5rem;
                font-weight: bold;
              }
              &:hover {
                background-color: $color-tertiary;
                color: #fff;
              }
            }
          }
        }
        .field-name-koda-uc-stock-field {
          display: flex;
        }
        .field-name-koda-uc-stock-low-stock,
        .field-name-koda-uc-stock-sold-out {
          color: $color-secondary;
          font-weight: bold;
          font-size: 1.4rem;
          text-transform: uppercase;
        }
        /** Price **/
        .product-info {
          &.sell-price {
            margin: 1rem 0;

            > span {
              color: $color-tertiary;
            }

            .uc-price {
              font-size: 4rem;
              font-weight: 700;
            }

            .uc-price-suffix {
              margin: auto 0 4px 1rem;
            }
          }
        }
        /** Add to Cart **/
        .add-to-cart {
          margin-top: 2rem;
          margin-bottom: 2rem;

          form {
            > div {
              display: flex;

              .form-item-qty {
                display: flex;
                width: auto;

                label {
                  display: flex;
                  font-size: 0;
                  padding-right: 1.5rem;
                  color: #000;

                  span {
                    font-size: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::before {
                      content: 'Qty';
                    }
                  }
                }

                input[type='text'] {
                  font-size: 2rem;
                  color: #0b0b0b;
                  text-align: center;
                  width: 8rem;
                  height: 100%;
                  border: 1px solid #d4d4d4;
                }
              }

              .form-actions {
                position: relative;
                margin-left: 1rem;
                > button {
                  @extend .btn-tertiary;
                  height: 100%;
                  font-size: 2.2rem;
                  .ajax-progress {
                    display: none;
                  }
                }
                .uc-ajax-cart-alt-status-messages {
                  position: absolute;
                  top: 100%;
                  right: 0;
                }
              }
            }
          }
        }
      }
      /*
      ======================================================
      Features & Benefits
      ======================================================
      */
      &__features-benefits {
        h3 {
          background: unset;
          border: 1px solid rgba(0, 85, 159, 0.2);
          padding: 1rem 2rem;
          display: flex;
          font-size: 1.4rem;
          justify-content: center;
          color: $color-primary;
          font-weight: bold;
          font-family: $base-font;

          > a {
            color: $color-primary;
            &::after {
              content: '\f107';
              font-family: $font-awesome;
              margin-left: 1rem;
            }
          }

          &.ui-state-active {
            border-bottom: none;
            > a {
              &::after {
                content: '\f106';
              }
            }
          }

          .field-name-body {
            color: $color-text;
          }
        }
        .ui-accordion-header-icon {
          display: none;
        }
        .ui-accordion-content {
          padding: 0 3rem 2rem 3rem;
          border-color: rgba(0, 85, 159, 0.2);
        }
      }

      /*
      ======================================================
      Price
      ======================================================
      */
      .product-info {
        &.sell-price {
          font-size: 2rem;
          color: #000;
          font-weight: 700;
        }
      }

      &__price-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }

      &__price-details {
        flex: 1;
        margin-top: 1.5rem;

        .product-info {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 1rem;

          span {
            font-size: 1.8rem;
            color: #000;
            line-height: 1;
            font-family: $base-font;

            &:first-child {
              font-weight: 400;
              margin-right: 1rem;
            }
          }
        }

        .add-to-cart {
          .alert {
            @extend .hide-after3s;
            .close {
              display: none;
            }
          }
        }
      }

      /** Previous  **/
      &__price {
        flex: 0 0 100%;
        display: flex;
        @extend .animation-element;
        @extend .animate__fadeIn;

        > .product-info.display-price {
          font-size: 2.5rem;
          padding: 0;
          font-weight: 500;
          color: $color-primary;
          margin-right: 2rem;
          .uc-price {
            opacity: 1;
            font-weight: bold;
          }
        }

        > .field-name-field-promote-price {
          @extend .animation-element;
          @extend .animate__fadeIn;
          font-size: 2.5rem;
          color: rgba(0, 53, 84, 0.5);
          .field-item {
            text-decoration: line-through;
            &::before {
              content: '$';
            }
          }
        }
      }
      .group-description-section {
        margin: 2rem 0;
      }
      /*
      ======================================================
      Cart
      ======================================================
      */
      &__cart {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 1rem 2rem;
        margin: 2rem 0;
        background-color: $color-tertiary;

        button {
          &[id*='wishlist'],
          &[id*='edit-add-to-wish'] {
            &::after {
              content: '\f004';
              font-family: $font-awesome;
              margin-left: 1rem;
            }
          }
        }

        .add-to-cart {
          width: 100%;
          .uc-out-stock-processed {
            margin-bottom: 0;
          }
          button {
            font-size: 1.8rem;
            .ajax-progress {
              background: url('../../images/loader2.gif') center center
                no-repeat;
              background-size: contain;
              width: 15px;
              height: 15px;
              margin-left: 5px;
            }
          }
          form > div {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .attribute {
              .form-control,
              .form-select {
                height: 4.5rem;
                background-color: #fff;
                border: 1px solid $color-primary;
              }
              .form-select {
                padding-left: 1rem;
                padding-right: 1rem;
              }
            }

            .form-type-select,
            #edit-actions,
            #edit-koda-wishlist {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              margin-top: 1rem;
              margin-bottom: 1rem;
              padding-right: 1rem;
            }

            /** Responsive **/
            @include less-than($screen-md) {
              flex-direction: column;
              .form-type-select,
              #edit-actions,
              #edit-koda-wishlist {
                margin-left: 0;
                padding-right: 0;
              }
            }

            > p {
              display: flex;
              align-items: center;
              padding: 0 1rem;
            }
            .alert {
              animation: 1s fadeOut;
              animation-delay: 1s;
              animation-fill-mode: forwards;

              font-size: 12px !important;
              width: 100%;
              margin: 0;
              position: absolute;
              display: flex;
              flex-wrap: wrap;
              right: 0;
              z-index: 50;
              top: 100%;
              border-radius: 0;
              padding-right: 3rem;
            }
          }
          .form-item-qty {
            width: auto;
            .control-label {
              display: none;
            }
            .form-control {
              width: 5rem;
              height: 4.5rem;
              border-radius: 0;
              text-align: center;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }

          .form-actions {
            display: flex;
            flex-direction: column;
            > .node-add-to-cart {
              order: 0;
            }
            > .uc-ajax-cart-alt-status-messages {
              order: 1;
              position: relative;
              .alert {
                position: absolute;
                right: 0;
                border-radius: 0;
                font-size: 12px;
                width: 22.8rem;
              }
            }
          }

          @include less-than($screen-md) {
            .form-actions {
              flex: 1;
            }
            fieldset {
              margin-left: 0;
              margin-top: 1rem;
              flex: 0 0 100%;
              button {
                width: 100%;
                min-width: unset !important;
              }
            }
          }
        }
      }

      /*
      ======================================================
      Notification
      ======================================================
      */
      &__notification {
        display: flex;
        flex: 1;
        .field-name-notify-in-stock-submitted {
          margin-bottom: 1rem;
          .notify-me-submitted {
            font-weight: bold;
            color: $color-primary;
            &::before {
              content: '\f058';
              font-family: $font-awesome;
              margin-right: 0.5rem;
              font-size: 2rem;
              color: $color-primary;
              font-weight: normal;
            }
          }
        }

        .field-name-notify-in-stock {
          .field-item {
            display: flex;
            margin-bottom: 1rem;
            align-items: center;
            &::before {
              content: '\f058';
              font-family: $font-awesome;
              margin-right: 0.5rem;
              font-size: 2rem;
              color: $color-primary;
              font-weight: normal;
            }
          }
          p {
            margin-bottom: 0;
          }
          .product-enquiry-link {
            font-weight: bold;
            display: flex;
            align-items: center;
            text-decoration: underline;
            color: $color-primary;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      /*
      ======================================================
      Tabs
      ======================================================
      */
      &__tabs {
        .product-enquiry-form {
          h2 {
            color: #000;
            font-size: 1.8rem;
          }
        }

        // Technical Info
        .field-name-field-chemical-resistance {
          .file-document {
            .file {
              display: flex;
              .file-icon {
                height: 15px;
                margin: 2px 2px 0 0;
              }
              a {
                &:hover {
                  text-decoration: underline;
                  color: $color-tertiary;
                }
              }
            }
          }
        }
      }

      /*
      ======================================================
      Responsive
      ======================================================
      */
      @include larger-than($screen-sm) {
        &__image {
          width: calc(100vw - 4rem);
        }
      }

      @include larger-than($screen-md) {
        margin-top: 2rem;
        flex-direction: row;
        &__image {
          width: 40rem;
          padding-right: 4rem;
          .slick-wrapper {
            .slide__content {
              padding-right: 1rem;
            }
            .slick--main {
              .slick__arrow {
                display: none;
              }
            }
            .slick--thumbnail {
              display: block;
              .slick__arrow,
              .slide__thumbnail {
                padding-right: 1rem;
              }
            }
          }
        }

        &__description {
          flex: 1;
          width: auto;
        }
      }

      @include larger-than($screen-xl) {
        &__image {
          width: 65rem;
          padding-right: 8rem;
        }
      }

      // Retailer Button
      .field-name-field-retailer-links {
        display: flex;
      }

      .retailers-button {
        @include animated;
        background-color: $color-tertiary;
        margin-top: 1rem;
        padding: 0.5rem 1.5rem;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $color-secondary;
        }

        &::before {
          content: url('../../images/icon--shop-online-sm.svg');
          margin-bottom: 0;
          margin-right: 1rem;
          line-height: 1;
        }

        .title {
          font-size: 1.5rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.2;
          letter-spacing: 0.1em;
        }

        .subtitle {
          font-size: 1.3rem;
          line-height: 1.2;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

.add-to-cart {
  > form {
    > div {
      flex-wrap: wrap;
    }
  }
  // Wishlist
  .panel.form-wrapper {
    display: flex;
    width: 100%;
    margin-top: 2rem;

    .panel-heading {
      font-weight: bold;
      display: flex;
      padding-bottom: 4px;
    }

    .panel-body {
      display: flex;

      button.btn {
        min-width: auto;
        font-size: 0;
        border-radius: 0;
        padding: 0 1rem;

        &::before {
          content: '\f067';
          font-family: $font-awesome;
          font-size: 18px;
          padding: 0 8px;
          line-height: 1;
          font-weight: normal;
          color: #fff;
        }
      }
    }
  }
}
