#preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url('../../images/loader2.gif') center center no-repeat
    rgba(255, 255, 255, 0.9);
}
/** Page that show preloader - js hides after load **/
body.page-cart-checkout {
  #preloader {
    display: flex;
    z-index: 100;
  }
}
