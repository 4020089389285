@mixin cart-table {
  width: 100%;
  thead {
    background: #0d3f6b;

    th {
      font-weight: 600;
      line-height: 151.69%;
      color: #ffffff;
      padding: 1rem 2rem;
      abbr {
        text-decoration: none;
      }
      &.price {
        text-align: right;
      }
      .uc-price {
        text-align: right;
      }
    }
  }

  tr {
    td {
      border: none;
    }
  }

  tbody {
    tr {
      &.subtotal {
        background: unset;
      }
    }
    td {
      font-weight: normal;
      line-height: 180.5%;
      letter-spacing: 0.02em;
      padding: 1rem 2rem;
      color: #3f3f3f;
      &.qty {
        input {
          background: #ffffff;
          border: 1px solid $color-secondary;
          text-align: center;
          margin: 0;
        }
      }
      &.price {
        font-weight: bold;
        font-size: 16px;
        line-height: 164.69%;
        text-transform: uppercase;
        color: #555555;
      }
      &.remove {
        text-align: center;
        button {
          font-size: 0;
          text-align: center;

          &::before {
            content: '\f00d';
            font-size: 16px;
            font-family: $font-awesome;
          }

          &:hover {
            color: $color-primary;
          }
        }
      }
      &.subtotal {
        font-size: 1.8rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: unset;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        #subtotal-title {
          font-weight: normal;
        }
        .uc-price {
          color: #000;
          font-weight: bold;
        }
      }

      @include less-than($screen-md) {
        &.remove {
          order: 10;
        }

        &.image {
          flex-basis: 140px;
        }

        &.desc {
          flex-basis: calc(100% - 140px);
        }
      }
    }
  }
}

/** Cart **/
.page-cart {
  .alert {
    strong {
      margin-right: 1rem;
    }
  }
  .order-review-table {
    margin-top: 2rem;
  }
  .block-system {
    margin-bottom: 8rem;
    #uc-cart-view-form-table {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    table {
      margin: 0;
      @include cart-table;
      tbody {
        tr {
          border: 1px solid #eaeaea;
          &:last-child {
            background-color: unset;
          }
          td {
            vertical-align: middle !important;
            &.image {
              width: 10%;
            }
            &.title {
              width: 20%;
              font-size: 1.8rem;
              font-weight: 600;
              a {
                &:hover {
                  color: $color-secondary;
                }
              }
            }
            &.desc {
              font-size: 1.8rem;
              font-weight: 600;
              color: #000;
              a {
                &:hover {
                  color: $color-secondary;
                }
              }
            }
            &.remove {
              width: 2rem;
              .btn {
                width: auto;
                background-color: unset;
                border: none;
                min-width: auto;
                padding: 0 !important;
                &::before {
                  content: url('../../images/icon--trash.svg');
                }
                &.progress-disabled {
                  &::before {
                    content: url('../../images/icon--update.svg');
                    @include animate-rotate;
                  }
                }
                .ajax-progress {
                  display: none;
                }
              }
            }
            &.qty {
              width: 10%;
              .form-item {
                input {
                  height: 28px;
                  width: 5rem;
                  color: #000;
                  border-color: #d0d0d0;
                  padding: 0;
                  font-weight: normal;
                }
              }
              .wrap-js-qty {
                display: flex;
              }
              .form-type-uc-quantity {
                display: flex;
                align-items: center;
                .increment {
                  outline: none;
                  cursor: pointer;
                  height: 28px;
                  display: flex;
                  width: 20px;
                  align-items: center;
                  justify-content: center;
                  color: $color-grey;
                  border: 1px solid #d0d0d0;
                  background-color: #fff;
                  font-size: 1.8rem;
                  &.plus {
                    border-radius: 3px 0 0 3px;
                    margin-right: -1px;
                  }
                  &.minus {
                    border-radius: 0 3px 3px 0;
                    margin-left: -1px;
                  }
                  &:hover {
                    background-color: #efefef;
                  }
                }
                > .control-label {
                  display: none;
                }
              }
            }
            &.price {
              width: 15%;
              text-align: center;
              color: #000;
            }
            &.subtotal {
              background-color: #fafafa;
              border-top-color: #e8e8e8;
              border-bottom-color: #e8e8e8;
            }
          }
        }
      }
    }

    .form-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;

      @include less-than($screen-md) {
        flex-direction: column;
        a[href*='/products'] {
          width: 100%;
        }
      }

      a[href*='/products'] {
        order: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 1.6rem;
        margin: 0 auto 0 0 !important;
        color: $color-orange;
        &::before {
          margin-right: 0.8rem;
          content: '\f104';
          font-family: $font-awesome;
        }
        &:hover {
          color: $color-tertiary;
        }
      }

      button {
        @extend .button;
        @extend .button-square;
        text-align: center;
        font-weight: normal;
        font-size: 1.6rem;
        &#edit-update-ajax {
          order: 1;
          background: unset;
          border: none;
          color: #000;
          &::before {
            content: url('../../images/icon--update.svg');
            margin-right: 0.5rem;
            line-height: 1;
          }
          &:hover {
            background: none;
            color: $color-tertiary;
          }
          &.progress-disabled {
            &::before {
              @include animate-rotate;
            }
          }
          .ajax-progress {
            display: none;
          }
        }

        &[value*='Checkout'] {
          text-transform: uppercase;
          order: 2;
          @extend .button-success;
        }

        @include less-than($screen-md) {
          width: 100%;
          margin-bottom: 2rem;
          margin-right: 0;
        }
      }

      button.btn-primary {
        @extend .button-success;
      }

      button.btn-default {
        @extend .button-primary;
      }
    }
  }
}

/** Cart Pane **/
#cart-form-pane {
  position: relative;
  width: 100%;
}
/** Coupon **/
#uc-cart-pane-coupon {
  padding: 0;
  border: none;
  #uc-coupon-form {
    h3,
    label {
      display: none;
    }
    > div {
      display: flex;
      button[name='uc-coupon-apply'] {
        height: 4.8rem;
        margin-left: 1.5rem;
      }
      #edit-code {
        margin-right: 1rem;
      }
      #edit-apply {
        background-color: #3f3f3f;
        border-color: #3f3f3f;
        min-width: auto;
      }
      .help-block {
        margin-top: 1rem;
      }
    }
    /** Responsive **/
    @include larger-than($screen-md) {
      width: 50rem;
    }
  }
}
