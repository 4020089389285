/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
@mixin link {
  color: $color-secondary;
  transition: color 0.3s;

  &:hover {
    color: lighten($color-secondary, 20%);
  }
}

@mixin grid-layout($cols, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($cols, minmax(0, 1fr));
}

@mixin squareImgBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin less-than($size) {
  @media all and (max-width: ($size - 1)) {
    @content;
  }
}

@mixin larger-than($size) {
  @media all and (min-width: $size) {
    @content;
  }
}

@mixin between($sizefrom, $sizeto) {
  @media all and (min-width: ($sizefrom + 1)) and (max-width: ($sizeto - 1)) {
    @content;
  }
}

@mixin block-title() {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 110px);
    height: 1px;
    background: #e3e3e3;
    top: 12px;
    right: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 104.19%;
    background-color: #fff;
    display: inline-block;
    position: relative;
    z-index: 10;
  }
}

@mixin animated($duration: 300ms) {
  transition: all $duration ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}

@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

@mixin imgZoomHover($zoom: 1.09) {
  @include animated;
  overflow: hidden;
  position: relative;
  display: block;

  img {
    @include animated(1000ms);
  }
  &:hover {
    img {
      @include scale($zoom);
    }
  }
}

@mixin animate-rotate() {
  -webkit-animation: rotating 2s linear infinite;

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}

/*------------------------------------*\
  Extend Background 100%
\*------------------------------------*/

@mixin extendbg($bgcolor, $bordercolor: $bgcolor) {
  background-color: $bgcolor;
  border-top: 1px solid $bordercolor;
  border-bottom: 1px solid $bordercolor;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: calc(100% + 2px);
    top: 0;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    background: $bgcolor;
    z-index: -1;
    visibility: visible;
    border-top: 1px solid $bordercolor !important;
    border-bottom: 1px solid $bordercolor !important;
  }
}

@mixin headerIcon($bgcolor, $iconFile: null) {
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  background-color: $bgcolor;
  background-image: url('../../images/#{$iconFile}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2.4rem 2.4rem;
}

@mixin headerFontIcon($bgcolor, $iconCode, $iconColor) {
  content: $iconCode !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  background-color: $bgcolor;
  font-family: 'Font Awesome 5 Pro';
  color: $iconColor;
  font-weight: 300;
  font-size: 18px;
}

/** Input **/
@mixin inputText($borderClr, $placeHolderclr: null) {
  border: 1px solid $borderClr;
  box-sizing: border-box;
  border-radius: 0;
  padding: 1rem;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0.02em;
  width: 100%;
  &::placeholder {
    color: $placeHolderclr;
  }
}
