.top-menu {
  @apply tw-w-full 
  tw-h-full 
  tw-hidden
  tw-bg-white;

  .region.region-top-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    min-height: 40px;
    .menu.nav {
      > li {
        color: #000;
        padding: 1rem clamp(0.2rem, 1vw, 2rem);
        font-size: $top-menu-font-size;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.04em;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .mainmenu {
    height: 100%;
    display: flex;
  }

  @include larger-than($screen-md) {
    @apply tw-flex;
  }
}
