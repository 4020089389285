/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user {
  #contentblock {
    margin: 200px auto;
    width: 80%;
  }
}

.not-logged-in {
  .tabs--primary {
    //display: none;
    justify-content: center;
  }
}

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0;
  flex-wrap: wrap;

  > li {
    padding: 1rem;
    &.active {
      > a {
        color: $color-secondary;
      }
    }
  }

  a {
    color: #333;
    margin-right: 1rem;
    font-size: 1.8rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
  @include less-than($screen-md) {
    flex-direction: column;
    justify-content: center;
  }
}

/*
======================================================
: User Login Page
======================================================
*/
.page-user {
  .region-content {
    form {
      //@include form;
    }
  }
}

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  z-index: 50;
  bottom: 0;
  padding: 1rem;
  height: auto;
  background-color: #333;
  color: #fff;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  margin: 0;

  h2 {
    margin-bottom: 0;
    margin-right: 1rem;
    color: #fff;
  }

  form {
    > div,
    .container-inilne {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    input {
      border-radius: 3px;
      background-color: #000;
      color: #c1c1c1;
      padding: 0.5rem;
      height: 28px;
      text-indent: 0.5rem;
    }

    button[type='submit'] {
      display: inline-block;
      margin: 0 2rem 0 0;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      border-radius: 3px;
      min-width: auto !important;
      background: #797979;
      border-color: #797979;

      &:hover {
        background-color: lighten(#797979, 10%);
      }

      @include button;
    }
  }
}
