/*------------------------------------*\
    Component: Button
\*------------------------------------*/

@mixin btn($bgColor: $color-primary) {
  font-weight: normal;
  font-family: $base-font;
  transition: all 0.3s;
  display: inline-flex;
  min-width: 16.8rem;
  padding: 0.8rem 2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 4px;
  border: 1px solid $bgColor;
  background-color: $bgColor;
  font-size: 1.5rem;

  i {
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    outline: none;
    //background: lighten($bgColor, 10%);
    //border-color: darken($bgColor, 2%);
  }
}

.btn-primary {
  @include btn($color-primary);
}

.btn-secondary {
  @include btn($color-secondary);
}

.btn-tertiary {
  @include btn($color-tertiary);
}

.btn {
  @include btn;

  &.btn-outline {
    background-color: unset;
    border: 2px solid #fff;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }
  }

  &.btn-outline-secondary {
    background-color: unset;
    border: 2px solid $color-primary;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }
  }
}
