/*------------------------------------*\
    Component: Slick Dots
\*------------------------------------*/

.slick {
  &__arrow {
    display: flex;
    justify-content: space-between;
    bottom: auto;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;

    .slick-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      border-radius: 0;
      &:before {
        color: #fff;
        line-height: 1;
        border-radius: 0;
        font-family: $font-awesome;
      }

      &.slick-prev {
        left: 1rem;

        &:before {
          content: '\f053';
        }
      }

      &.slick-next {
        right: 1rem;

        &:before {
          content: '\f054';
        }
      }
    }
  }
}
