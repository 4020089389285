/*------------------------------------*\
  Distributors
\*------------------------------------*/
.view-distributors {
  .view-content {
    .views-row {
      border-top: 1px dashed #acacac;
      &:nth-child(odd) {
        background-color: #fafafa;
      }
    }
  }
  .views-exposed-widgets {
    margin-bottom: 2rem;
    .views-exposed-widget {
      margin-top: 0;
      padding: 0;
      select.form-control {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 45px;
        background-color: #fff;
        font-family: $base-font;
        border: 1px solid #dddddd;
        option {
          font-family: $base-font;
        }
      }
      .form-submit {
        margin-top: 0;
      }
    }
  }
}

.distributor {
  /** Teaser **/
  &--teaser {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    color: #000;
    a {
      color: $color-primary;
    }
    .field-label {
      font-weight: normal;
    }
    .field-name-field-country {
      width: 100%;
      .taxonomy-term {
        display: flex;
        h2 {
          background-color: #000;
          font-size: 1.8rem;
          font-weight: normal;
          padding: 0.8rem 2rem;
          a {
            color: #fff;
          }
          &:hover {
            background-color: $color-tertiary;
          }
        }
      }
    }
    .field-name-field-logo {
      margin-bottom: 1rem;
    }
    .field-name-title {
      h2 {
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
    .field-name-body {
      .field-item {
        font-weight: normal;
        > p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &__details {
      flex: 1;
    }
    &__map {
      flex: 0 0 100%;
      @include larger-than($screen-md) {
        flex: 0 0 50%;
        padding-left: 20px;
      }
      @include larger-than($screen-xl) {
        flex: 0 0 720px;
        padding-right: 40px;
      }
    }
  }
}
