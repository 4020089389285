/*------------------------------------*\
  Featured Content
\*------------------------------------*/
.featured-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 4rem;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    margin-top: 4rem;
    display: flex;
    .field {
      display: flex;
      align-items: center;

      .field-items,
      .field-item {
        align-items: center;
      }
      .field-items,
      .field-item,
      img {
        width: 100%;
      }
      &:first-child {
        margin-right: -5%;
        z-index: 10;
        width: 50%;
      }
      &:nth-child(2) {
        width: 60%;
      }
    }
  }

  @include larger-than($screen-md) {
    flex-direction: row;
    &__left {
      flex: 0 0 30%;
      padding-right: 7rem;
    }
    &__right {
      flex: 1;
      margin-top: 0;
    }
  }
}
