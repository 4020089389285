/*------------------------------------*\
  Menu Component - used globally
\*------------------------------------*/
.menu.nav {
  display: flex;
  a {
    @include animated;
  }

  > .dropdown {
    > .dropdown-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .dropdown {
    > .dropdown-menu {
      @include animated;
      display: flex;
      flex-direction: column;
      visibility: hidden;
      > li {
        display: flex;
        margin-left: 0;
        a {
          display: flex;
          width: 100%;
          padding: 1rem 1.5rem;
          word-break: unset;
        }
      }
    }
  }

  /** Responsive **/

  @include larger-than($screen-md) {
    .dropdown {
      position: relative;
      .dropdown-menu {
        position: absolute;
        z-index: 5;
        background-color: #fff;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        color: inherit;
        visibility: hidden;
        opacity: 0;
        min-width: 200px;
        width: 100%;
        //max-width: 200px;
      }

      > .dropdown-menu {
        top: 100%;

        > li {
          color: #003554;
          &.dropdown {
            position: relative;
            .dropdown-menu {
              top: 0;
              left: 100%;
            }
          }
        }
      }

      &:hover {
        > .dropdown-menu {
          visibility: visible;
          opacity: 1;
          display: flex;
        }
      }
    }
  }
}

/** Top Menu **/
.top-menu {
  .menu.nav {
    > .dropdown {
      &:last-child {
        > .dropdown-menu {
          /** Sub menu **/
          > .dropdown {
            &::before {
              content: '\f104';
              font-family: $font-awesome;
              font-style: normal;
              font-size: 16px;
              font-weight: 100;
              line-height: 1;
              width: 10px;
              padding-right: 2rem;
              color: inherit;
              display: inline-block;
            }
          }
          .dropdown {
            > .dropdown-menu {
              right: 100%;
              left: auto;
              margin-left: 0;
            }
          }
        }
      }
    }
    .dropdown {
      &:not(:last-child) {
        > .dropdown-menu {
          .dropdown {
            &::after {
              content: '\f105';
              font-family: $font-awesome;
              font-style: normal;
              font-size: 16px;
              font-weight: 100;
              line-height: 1;
              width: 10px;
              padding-right: 2rem;
              color: inherit;
              display: inline-block;
            }
          }
        }
      }
      > .dropdown-menu {
        min-width: 290px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 1rem clamp(0.2rem, 1vw, 2rem);
        margin-top: -1rem;

        > li {
          padding: 1.5rem 1rem 1.5rem 1.5rem;
          min-height: 4.6rem;
          border-right: 1px solid #eae9e5;
          border-left: 1px solid #eae9e5;
          border-bottom: 1px solid #eae9e5;

          &:hover {
            background-color: $color-primary;
            &.dropdown {
              &::before,
              &::after {
                color: #fff !important;
              }
            }
            > a {
              color: #fff;
            }
          }

          &:first-child {
            border-top: 1px solid #eae9e5;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
          }

          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          > a {
            color: #000;
            display: flex;
            padding: 0;
            line-height: 1.2;
            text-transform: none;
            font-weight: normal;
          }
        }
      }
    }
  }
}
