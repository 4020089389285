// Reusable Gallery Tile with light gallery
.gallery-tile {
  .lightgallery {
    @apply tw-grid tw-grid-cols-2 tw-gap-8;
    img {
      @apply tw-w-full tw-object-cover;
      height: 30rem;
    }
    a {
      @include imgZoomHover;
    }
    @include larger-than($screen-lg) {
      @apply tw-grid-cols-5;
      img {
        height: 25rem;
      }
    }
  }
}
