/*------------------------------------*\
    Typography 
\*------------------------------------*/

html {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  font-size: $base-font-size;
  font-family: $base-font;
  color: $color-text;
}

p {
  font-size: $base-font-size;
  line-height: 1.5;
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

@mixin h1 {
  font-size: 3rem;
  line-height: 1.3;
  @include less-than($screen-sm) {
    font-size: 26px;
    font-weight: 600;
  }
}

h1 {
  color: #000;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  @include h1;
}

h2 {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 127.88%;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
}

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
  color: #262626;
}

a {
  @include animated;
  word-break: break-word;
  &.underline {
    text-decoration: underline;
  }
}

b,
strong {
  font-weight: bold;
}

button {
  outline: none;
}

blockquote {
  color: #555555;
  width: 100%;
  padding: 2rem;
  border-left: 4px solid $color-primary;
  background-color: #fafafa;
  margin-bottom: 2rem;
  p {
    font-style: italic;
  }
}

hr {
  margin-bottom: 1.5rem;
}

/*------------------------------------*\
  Body
\*------------------------------------*/

.main-content {
  .block {
    h2 {
      font-weight: bold;
    }
    > p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    ol {
      list-style: decimal;
      list-style-position: outside;
      margin-bottom: 1rem;
      margin-left: 1rem;
    }

    ul {
      &.list {
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 1rem;
      }
    }
  }

  .field-type-text-long,
  .field-name-body {
    font-size: $base-font-size;
    p {
      margin-bottom: 1.5rem;
    }

    ol,
    ul {
      font-size: inherit;
      margin-bottom: 1.5rem;
      li {
        font-size: inherit;
        margin-bottom: 1rem;
      }
    }

    ul {
      margin-left: 2rem;
      li {
        font-size: inherit;
        list-style: disc;
      }
    }

    // ul{
    //   margin-left: 0;
    //   padding: 0;
    //   list-style: none;
    //   > li{
    //     position: relative;
    //     padding-left: 2rem;
    //     line-height: 2;
    //     &::before{
    //       position: absolute;
    //       margin-left: -2rem;
    //       content: "\2d";
    //       font-family: $font-awesome;
    //       font-weight: normal;
    //       color: $color-secondary;
    //     }
    //   }
    // }

    ol {
      list-style: decimal;
      //list-style-position: inside;
      list-style-position: outside;
      padding-left: 1rem;
    }

    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }

    img {
      max-width: 100%;
      &.align-left {
        margin: 0 40px 20px 0;
      }
      &.align-right {
        margin: 0 0 20px 40px;
      }
      &.align-center {
        margin-bottom: 20px;
      }
    }
  }
}
