/*------------------------------------*\
  Description Teaser
\*------------------------------------*/

.description-teaser {
  @apply tw-relative tw-flex tw-w-full tw-h-full;
  padding-bottom: 125%;
  background-color: #000;
  @include animated;
  @include imgZoomHover;

  &__content {
    @apply tw-absolute tw-flex tw-flex-col tw-justify-end tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-20 tw-p-8 tw-text-white;
    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 56.01%);
    border-width: 0.2px;
    border-style: solid;
    border-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.25),
        rgba(0, 0, 0, 0.2)
      )
      1 100%;
    p {
      font-size: clamp(1.4rem, 2vw, 1.6rem);
    }
  }

  > img {
    @apply tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-object-cover;
  }

  h3 {
    @apply tw-text-white;
    font-size: clamp(2rem, 3vw, 3rem);
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.2rem;
    @include animated;
  }

  .link-title {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: $color-tertiary;
    letter-spacing: 0.05rem;
  }

  .link-wrapper {
    @apply tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-40;
  }

  &:hover {
    h3 {
      color: $color-tertiary;
    }
  }

  /** Responsive **/
  @include larger-than($screen-xl) {
    padding-bottom: 90%;
    &__content {
      @apply tw-p-16;
    }
  }

  @include less-than($screen-sm) {
    padding-bottom: 80%;
  }
}
