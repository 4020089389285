/*------------------------------------*\
  International
\*------------------------------------*/
.international {
  &--tabs {
    .quicktabs-tabs {
      display: flex;
      flex-wrap: wrap;
      border-bottom: none;
      > li {
        background-color: #262626;
        color: #fff;
        border-radius: 6px 6px 0px 0px;
        margin-top: 1rem;
        text-align: center;
        flex-basis: calc(50% - 1px);
        &.active {
          background-color: $color-secondary;
          color: #fff;
          border-bottom: $color-secondary;
        }
        > a {
          display: flex;
          padding: 1rem 2rem;
        }
        &:not(:first-child) {
          margin-left: 1px;
        }
      }
      /** Responsive **/
      @include larger-than($screen-sm) {
        border-bottom: 1px solid #262626;
        > li {
          flex-basis: auto;
        }
      }
    }

    .international_content_title {
      margin-top: 3rem;
      margin-bottom: 3rem;
      padding: 1rem 1.5rem;
      color: #292929;
      background-color: #f1f1f1;
    }
  }
}
