/*------------------------------------*\
  Wishlist
\*------------------------------------*/

.page-user-wish-list,
.page-user-wishlist {
  #uc-wishlist-create-list-form {
    margin-bottom: 2rem;
    width: 100%;
    @include larger-than($screen-md) {
      width: 50%;
    }
    > div {
      display: flex;
      align-items: flex-end;
      > .form-submit {
        height: 48px;
      }
    }
  }

  .table {
    width: 100%;
    tr {
      th {
        &:last-child {
          width: 10%;
        }
      }

      th,
      td {
        padding: 1rem;
        border: 1px solid #e2e8f0;
      }

      td {
        text-align: center;
        font-weight: bold;

        &:first-child {
          text-align: left;
          a {
            color: $color-primary;
          }
        }
        .ctools-use-modal {
          font-size: 0;
          &::before {
            font-family: $font-awesome;
            font-size: 2rem;
          }
          &.ctools-modal-update-wishlist-item-qty {
            &::before {
              content: '\f044';
            }
          }
          &.ctools-modal-remove-wishlist-item {
            &::before {
              content: '\f2ed';
            }
          }
        }
      }
    }
  }

  // Modal
  #uc-wishlist-update-item-qty-form {
    .form-item {
      .control-label {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }
  #edit-btn-group {
    margin-top: auto;
  }
  #edit-btn-group-body {
    display: flex;
    width: 100%;

    margin-top: 1rem;
    margin-bottom: 1rem;
    .ctools-close-modal {
      position: relative;
    }
    .btn {
      flex: 1;
    }
  }

  .detailed-list-checkout {
    @extend .btn;
    @extend .btn-primary;
    margin-bottom: 1rem;
  }

  // Table
  table {
    &.table {
      width: 100%;
      thead {
        tr {
          th {
            font-size: inherit;
            color: #000;
            font-size: 16px;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #ccc;
            padding-bottom: 1rem;
            &:first-child {
              text-align: left;
            }
          }
        }
      }

      td {
        border: none;
      }

      td {
        color: #000;
        a {
          color: inherit;
          &:hover {
            color: #7cb434;
          }
        }

        .field-name-uc-product-image {
          img {
            height: 80px;
            object-fit: contain;
          }
        }

        .ctools-modal-wishlist-to-checkout,
        .ctools-modal-remove-wishlist,
        .ctools-modal-add-wishlist-item-to-cart,
        .ctools-modal-update-wishlist-item-qty,
        .ctools-modal-remove-wishlist-item {
          font-size: 0;
          &::before {
            font-family: $font-awesome;
            font-size: 18px;
            padding: 0 8px;
            font-weight: normal;
          }
        }
        .ctools-modal-wishlist-to-checkout,
        .ctools-modal-add-wishlist-item-to-cart {
          &::before {
            content: '\f217';
          }
        }
        .ctools-modal-update-wishlist-item-qty {
          &::before {
            content: '\f2f1';
          }
        }
        .ctools-modal-remove-wishlist,
        .ctools-modal-remove-wishlist-item {
          &::before {
            content: '\f2ed';
          }
        }
      }

      tbody {
        tr {
          td {
            border-bottom: 1px solid #ccc;
            &:first-child {
              > a {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .wishlist-footer {
    @apply tw-flex;
    @apply tw-justify-end;
    @apply tw-mt-8;

    .ctools-use-modal {
      @extend .btn;
      background-color: #7cb434;
      border-color: #7cb434;
    }
  }

  #uc-wishlist-create-list-form {
    > div {
      display: flex;
      flex-direction: row;

      .form-item-uc-wishlist-title {
        display: flex;
        align-items: center;
        width: auto;
        flex: 0 0 auto;
        label {
          padding-right: 2rem;
          flex: 0 0 auto;
          font-weight: bold;
          color: #000;
        }
        .form-control {
          width: auto;
        }
      }

      @include less-than($screen-md) {
        flex-direction: column;
        .form-item-uc-wishlist-title {
          flex-direction: column;
          flex: 1;
          .form-control {
            width: 100%;
          }
        }
      }
    }
  }
}

.node-product {
  &.view-mode-wishlist {
    display: flex;
    align-items: center;
    .field-name-uc-product-image {
      padding-right: 1rem;
    }
    h3 {
      margin-bottom: 0;
    }
  }

  .product-wishlist {
    display: flex;
    .field-name-uc-product-image {
      margin-right: 1rem;
    }
    &__info {
      display: flex;
      align-items: center;

      h3 {
        width: 100%;
        line-height: 1;
        text-align: left;
        margin-bottom: 0;
        font-size: 1.8rem;
        line-height: 1;
      }

      .display-price,
      .field-name-field-promote-price {
        display: flex;
        justify-content: center;
        line-height: 1;
      }
      .display-price {
        width: auto;
        opacity: 1;
        margin-right: 1rem;
        margin-left: 1rem;
        padding: 0;
        .uc-price {
          opacity: 1 !important;
          font-size: 1.6rem;
        }
      }
      .field-name-field-promote-price {
        width: auto;
        font-size: 1.6rem;
        text-decoration: line-through;
        color: $color-secondary;
        font-weight: normal;
      }
    }
  }
}

#modal-content {
  #uc-wishlist-add-list-item-to-cart-form {
    background-color: red;
    .panel-body {
      display: flex;
      > button {
        flex: 1;
        width: auto;
        border-radius: 0;
      }
    }
  }
}
