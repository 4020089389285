/*------------------------------------*\
  Colorbox
\*------------------------------------*/
#colorbox {
  #cboxWrapper {
    border-radius: 0;
  }
  #cboxClose {
    bottom: auto;
    top: 0;
    background: url('../../images/icon--close.svg') center center no-repeat !important;
    font-size: 0;
    width: 26px;
    height: 26px;
  }
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    border-radius: 0 !important;
  }
  #cboxLoadedContent {
    margin-bottom: 0 !important;
  }
}
