/*------------------------------------*\
  Page User
\*------------------------------------*/

.page-user {
  .region-content {
    display: flex;

    @include less-than($screen-md) {
      flex-direction: column;
    }

    section[id*='related-users'] {
      flex-basis: 35%;
      padding-left: 5%;

      @include less-than($screen-md) {
        flex-basis: 100%;
        padding-left: 0;
        margin-bottom: 3rem;
        order: -1;
      }

      .view-related-users {
        .views-row {
          margin-bottom: 1rem;

          a {
            color: #259de1;
            text-decoration: underline;
          }
        }
      }
    }
    // User Login
    #user-login,
    #user-pass,
    #user-register-form {
      .form-item {
        margin-bottom: 2rem;
      }
      @include larger-than($screen-md) {
        width: 600px;
        margin: 2rem auto;
      }
    }
  }
  .user-profile {
    .field {
      margin-bottom: 2rem;
      border: 1px solid #eee;
      padding: 2rem;
    }

    h3 {
      margin-top: 4rem;
      font-size: 3rem;
    }

    dl {
      margin-bottom: 2rem;
    }
  }

  .region-content {
    #block-system-main {
      width: 100%;
    }
  }

  /*
  ======================================================
   Block: Logged In
  ======================================================
  */
  &.logged-in {
    #block-system-main {
      width: 100%;
    }

    .profile {
      border: 1px solid #eee;
      padding: 2rem;
      dl {
        margin-bottom: 1rem;
      }
    }

    .address-book {
      .default-addresses {
        padding-top: 2rem;
        width: 100%;
        .address-item {
          display: flex;
          flex-direction: column;
          .list-address-wrapper {
            width: 100%;
            table {
              width: 100%;
            }
          }
        }
      }
      .address-links {
        a {
          @extend .btn;
          @extend .btn-primary;
        }
      }
    }

    #uc-addresses-get-address-form {
      padding-top: 2rem;
      fieldset {
        margin-bottom: 2rem;
        legend.panel-heading {
          font-weight: bold;
          font-size: 2rem;
        }
        .panel {
          margin-bottom: 2rem;
        }
        .chosen-single,
        .form-select {
          height: 4.8rem;
          border-radius: 0;
          border: 1px solid $color-secondary;
        }
        .chosen-container {
          width: 100%;
        }
        .chosen-single {
          border: none;
          display: flex;
          align-items: center;
          background: none;
          box-shadow: none;
          font-size: 1.6rem;
          > div {
            b {
              background: url('../../images/icon--select-arrow.png') center
                center no-repeat;
            }
          }
        }
      }
      .address-link {
        @extend .btn;
      }
    }
  }

  /*
  ======================================================
   User Address
  ======================================================
  */
  #edit-uc-addresses-body {
    .chosen-container-single {
      width: 100% !important;
    }
  }

  /*
  ======================================================
   Tab Primary
  ======================================================
  */
  .tabs--primary {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4rem;
    padding: 0;
    border: none;
    border-bottom: 1px solid #e4e4e4;

    > li {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.4rem;
      &.active {
        border-bottom: 2px solid $color-secondary;
        > a {
          color: #333;
          margin: 0;
        }
      }
      > a {
        font-size: inherit;
      }
    }

    // Responsive
    @include larger-than($screen-sm) {
      gap: 1.5rem;
      > li {
        font-size: 1.8rem;
      }
    }
  }

  /*
  ======================================================
  Orders Page
  ======================================================
  */

  &.page-user-orders {
    .block {
      .table {
        margin-top: 0;
        position: relative;

        @include less-than($screen-sm) {
          min-width: 600px;
        }

        .views-field {
          &[class*='order-id'] {
            display: flex;
            a {
              &::before {
                display: none;
              }
              &:first-child {
                padding-right: 1rem;
              }
            }
          }
        }

        thead {
          > tr {
            > th {
              font-weight: bold;
              color: #000;
              padding-top: 1rem;
              padding-bottom: 1rem;
              text-align: left;

              &:first-child {
                text-align: left;
              }
            }
          }
        }

        tr {
          > td {
            border: none;
            padding: 1rem 0;
            font-weight: 600;
            color: #000;
            > a {
              color: $color-primary;
            }
          }
          &:not(:first-child) {
            > td {
              border-top: 1px solid #e4e4e4;
            }
          }
        }
      }
    }
  }

  /*
  ======================================================
   Dashboard
  ======================================================
  */
  &.page-user-dashboard {
    .content-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;

      > .region {
        &.region-content-left,
        &.region-content-right {
          flex: 1 !important;
          width: auto;
          padding-left: 0;
          padding-right: 0;
        }

        // Responsive
        @include less-than($screen-sm) {
          &.region-content-left,
          &.region-content-right {
            flex: 0 0 100% !important;
            width: 100% !important;
          }
        }

        > .block {
          position: relative;
          width: 100%;
          padding: 3rem;
          margin-bottom: 4rem;
          border: 1px solid #e4e4e4;
          border-radius: 6px;
          overflow: hidden;
          z-index: 5;
          -webkit-box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);
          -moz-box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);
          box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);

          .block-title {
            color: #000;
            font-size: 2rem;
            margin-bottom: 1rem;
            z-index: 50;
          }

          .view {
            flex: 0 0 100%;

            .view-header {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-bottom: 0;
              a {
                color: $color-primary;
                font-weight: 600;
              }
            }

            .views-field {
              &[class*='order-id'] {
                display: flex;
                a {
                  &::before {
                    display: none;
                  }
                  &:first-child {
                    padding-right: 1rem;
                  }
                }
              }
            }
          }

          .table {
            margin-top: 0;
            position: relative;
            border-top: 2px solid #e4e4e4;

            @include less-than($screen-sm) {
              min-width: 600px;
            }

            > caption {
              top: -3.5rem;
              position: absolute;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              > a {
                color: $color-primary;
                font-weight: 600;
              }
            }

            thead {
              > tr {
                > th {
                  font-weight: bold;
                  color: #000;
                  padding-top: 1rem;
                  padding-bottom: 1rem;
                  text-align: left;
                  &:first-child {
                    text-align: left;
                  }
                }
              }
            }

            tr {
              > td {
                border: none;
                padding: 1rem 0;
                font-weight: 600;
                color: #000;
                a {
                  color: $color-primary;
                  &[href*='edit'] {
                    font-size: 0;
                    &::before {
                      font-family: $font-awesome;
                      content: '\f013';
                      font-size: 16px;
                      font-weight: 400;
                    }
                  }
                  &[href*='delete'] {
                    font-size: 0;
                    &::before {
                      font-family: $font-awesome;
                      content: '\f2ed';
                      font-size: 16px;
                      font-weight: 400;
                    }
                  }
                }
                .field-name-uc-product-image {
                  img {
                    height: 80px;
                    object-fit: contain;
                  }
                }
              }
              &:not(:first-child) {
                > td {
                  border-top: 1px solid #e4e4e4;
                }
              }
            }
          }

          &.dashboard-links {
            padding: 1.5rem;
            margin-bottom: 2rem;

            .admin-link {
              font-size: 18px;

              &::before {
                content: '\f058';
                font-weight: 600;
                font-family: $font-awesome;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  /*
  ======================================================
  Address
  ======================================================
  */
  .list-address-wrapper {
    border-radius: 8px;
  }

  .list-address {
    tbody {
      > tr {
        border-radius: 8px;
        &:not(.address-links) {
          &:nth-child(odd) {
            background-color: #f0f0f0;
          }
        }

        > td {
          padding: 1rem;
          color: #000;
        }
      }
    }
  }

  .title-col {
    font-weight: bold;
  }
  .address-links {
    border-top: 1px solid #e4e4e4;
    .address-link {
      font-size: 12px;
    }
  }

  &.page-user-addresses {
    .address-book {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #000;

      ol {
        margin-left: 0;
      }

      .additional-addresses {
        position: relative;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #cccccc;
        padding: 10px 13px;

        ol {
          padding: 2rem;
          border: 1px solid #e4e4e4;
          border-radius: 6px;
          overflow: hidden;
          -webkit-box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);
          -moz-box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);
          box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.09);
          > li {
            list-style: none;
            padding: 0;
          }
        }

        table.list-address {
          width: 100%;
        }
      }
    }
  }
}
