/*------------------------------------*\
  Add to cart
\*------------------------------------*/
.add-to-cart {
  .wrap-js-qty {
    display: flex;
    align-items: stretch;
    .form-text {
      margin: 0;
      border-color: #d4d4d4;
    }
    .increment {
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 3rem;
      cursor: pointer;
      font-size: 2rem;
      border-style: solid;
      border-color: #d4d4d4;
      background-color: #fff;
      &.minus {
        border-radius: 0 3px 3px 0;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
      }
      &.plus {
        border-radius: 3px 0 0 3px;
        border-top-width: 1px;
        border-left-width: 1px;
        border-bottom-width: 1px;
      }

      &:hover {
        background-color: #efefef;
      }
    }
  }
}
