/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/

/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none;
}

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center;
}

/*
======================================================
 : Responsive Table
======================================================
*/
.table-responsive {
  @include less-than($screen-md) {
    min-height: 0.01%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table {
      margin-bottom: 0;
      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
  Page title
\*------------------------------------*/

main {
  .page_header {
    width: 100%;
    .page-header {
      width: 100%;
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
}

/*
======================================================
 Block: 
======================================================
*/

.block-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url('../../images/loader2.gif') center center no-repeat
    rgba(255, 255, 255, 0.5);
  z-index: 10;
}

/*
======================================================
 Logged User
======================================================
*/
body {
  &:not(.logged-in) {
    .logged-in-only {
      display: none !important;
    }
  }
}

/*
======================================================
 Responsive
======================================================
*/
.mobile-only {
  @include larger-than($screen-md) {
    display: none !important;
  }
}

.desktop-only {
  @include less-than($screen-md) {
    display: none !important;
  }
}

.large-only {
  @include less-than($screen-lg) {
    display: none !important;
  }
}

.wide-only {
  @include less-than($screen-xxl) {
    display: none !important;
  }
}

/** Link **/
.back-link {
  color: $color-orange;
  font-weight: bold;
  &::before {
    content: '\f104';
    font-family: $font-awesome;
    margin-right: 0.5rem;
  }
}

/*
======================================================
 Common : content columns
======================================================
*/

.row-two-cols {
  @apply tw-w-full tw-grid tw-gap-8;
  @include larger-than($screen-md) {
    @apply tw-grid-cols-2 tw-gap-32;
  }
}

/*
======================================================
 Common : Horizontal Tabs
======================================================
*/
.horizontal-tabs {
  border: none !important;
  margin: 0;
  position: relative;

  a {
    padding: 0;
    margin: 0;
    &:hover {
      background-color: unset;
    }
  }

  ul.horizontal-tabs-list {
    display: flex;
    background-color: unset;
    border: unset;
    flex-wrap: wrap;
    border: none;
    li {
      position: relative;
      border-right: unset;
      background-color: unset;
      margin: 0;
      padding: 0;
      &:hover {
        background-color: unset;
        a {
          background-color: unset;
        }
      }
    }
  }

  .horizontal-tabs-panes {
    border: none !important;
  }
}

/*
======================================================
 Product Tabs - Global
======================================================
*/
.product__tabs {
  @include globalTabs;
}
