/*------------------------------------*\
  Input Styles
\*------------------------------------*/
input {
  &[type='text'],
  &[type='password'],
  &[type='email'] {
    @include inputText(#cccccc, #898581);
  }
}
textarea {
  @include inputText(#cccccc, #898581);
}
.form-item,
.form-select {
  width: 100%;
}
.form-select {
  background: none;
  color: #898581;
  @include inputText(#cccccc, #898581);
}
