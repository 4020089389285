.node-careers {
  &.node-teaser {
    position: relative;
    color: #000;
    padding: 3rem 4rem;

    h2.careers-title {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      letter-spacing: unset;
      font-weight: 500;
    }

    h3.careers-location {
      font-size: 1.6rem;
      font-weight: normal;
      font-style: italic;
    }

    .field-name-field-apply-now-link {
      a {
        @extend .btn-primary;
      }
    }

    @include less-than($screen-sm) {
      padding: 3rem 2rem;
    }
  }
}

.view-careers {
  > .view-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .btn {
      @include btn($color-orange);
    }
  }

  > .view-content {
    > .views-row {
      &:nth-child(odd) {
        .node-careers.node-teaser {
          background-color: #fafafa;
        }
      }

      &:first-child {
        .node-careers.node-teaser {
          border-top: 1px dashed #acacac;
        }
      }

      .node-careers.node-teaser {
        border-bottom: 1px dashed #acacac;
      }
    }
  }
}
