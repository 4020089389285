/*------------------------------------*\
  Views
\*------------------------------------*/

.view {
  .view-header {
    margin-bottom: 2rem;
  }
  .view-content {
    .views-row {
      .node-teaser {
        height: 100%;
      }
    }
  }
}

.view-five-columns {
  .view-content {
    @extend .five-cols-grid;
  }
}

.view-four-columns {
  .view-content {
    @extend .four-cols-grid;
  }
}

.view-three-columns {
  .view-content {
    @extend .three-cols-grid;
  }
}

.view-two-to-five-columns {
  .view-content {
    @extend .two-to-five-cols-grid;
  }
}

.view-two-to-four-columns {
  .view-content {
    @extend .two-to-four-cols-grid;
  }
}

.view-two-columns {
  .view-content {
    @extend .two-cols-grid;
  }
}

/** Grids **/

.two-cols-grid {
  display: grid;
  grid-gap: 4rem;
  @include larger-than($screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.two-to-four-cols-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include larger-than($screen-lg) {
    grid-gap: 2rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include larger-than($screen-xl) {
    grid-gap: 4rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.two-to-five-cols-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include larger-than($screen-lg) {
    grid-gap: 2rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include larger-than($screen-xl) {
    grid-gap: 3rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.three-cols-grid {
  display: grid;
  grid-gap: 4rem;
  @include larger-than($screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include larger-than($screen-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.four-cols-grid {
  display: grid;
  grid-gap: 4rem;
  @include larger-than($screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include larger-than($screen-lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.five-cols-grid {
  display: grid;
  grid-gap: 4rem;
  @include larger-than($screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include larger-than($screen-lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
