/*------------------------------------*\
 Sidebar
\*------------------------------------*/

body {
  &.sidebar-first,
  &.one-sidebar {
    > .content {
      .tw-container {
        main {
          position: relative;
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .page_header {
            width: 100%;
          }

          // Sidebar
          .sidebar {
            background-color: #fafafa;
            padding: 2rem;

            @include less-than($screen-md) {
              padding: 0;
              .sidebar-trigger {
                display: flex !important;
                position: fixed;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 15px;
                font-size: 14px;
                z-index: 55;
                height: 100%;
                cursor: pointer;
                > a {
                  @include animated;
                  background-color: rgba(220, 123, 31, 0.9);
                  color: #fff;
                  padding: 0.2rem 0.5rem;
                  transform: rotate(-90deg);
                  position: absolute;
                  width: 150px;
                  height: 30px;
                  font-size: 14px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:hover {
                    background-color: rgba(220, 123, 31, 1);
                  }
                  &::after {
                    content: '\f067';
                    font-family: $font-awesome;
                    margin-left: 1rem;
                    font-weight: 100;
                    @include animated;
                  }
                }
              }

              &.opened {
                .region {
                  transform: translateX(0);
                }
                .sidebar-trigger a {
                  background-color: rgba(0, 0, 0, 0.6);
                  &::after {
                    transform: rotate(-45deg);
                    color: $color-primary;
                  }
                }
              }

              .region {
                @apply tw-fixed
                  tw-w-full
                  tw-h-full
                  tw-left-0
                  tw-top-0
                  tw-p-16
                  tw-z-50;
                overflow-y: scroll;
                overflow-x: hidden;
                transform: translateX(-100%);
                background-color: #fafafa;
                @include animated;
              }

              .sidebar-toggle {
                display: flex;
                position: fixed;
                justify-content: center;
                align-items: center;
                left: -6.5rem;
                top: 0;
                font-size: 14px;
                z-index: 55;
                height: 100%;
                cursor: pointer;

                > span {
                  background-color: #31311e;
                  color: #fff;
                  padding: 0.2rem 0.5rem;
                  transform: rotate(-90deg);
                  position: absolute;
                  left: 0;
                  width: 150px;
                  text-align: center;
                }
              }
            }

            @include larger-than($screen-md) {
              width: 300px;
            }

            section {
              margin-bottom: 2rem;
              h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 127.69%;
                color: #1a1a1a;
              }
            }

            .search-api-ranges-widget {
              .text-range {
                font-size: 12px;
              }

              .yui3-g {
                position: relative;
                margin: 10px 0 6rem;

                .range-box {
                  position: absolute;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 176.69%;
                  color: #1a1a1a;
                  bottom: -40px;

                  label {
                    display: none;
                  }

                  input[type='text'] {
                    width: 50px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #1a1a1a;
                    background-color: #fafafa;
                  }

                  &-left {
                    left: 0;
                  }

                  &-right {
                    right: 0;
                    input[type='text'] {
                      text-align: right;
                    }
                  }
                }

                .range-slider-box {
                  .range-slider {
                    background: #eee;
                  }

                  .ui-slider-horizontal {
                    height: 2px;
                    border: none;
                  }

                  .ui-slider-range {
                    background: $color-primary;
                  }

                  .ui-slider-handle {
                    border-radius: 0;
                    width: 11px;
                    background: $color-grey;
                    border: 1px solid #ffffff;
                    top: -0.5em;
                    margin-left: 0;
                    border-radius: 8px;

                    &:last-child {
                      margin-left: -0.6em;
                    }
                  }
                }

                ~ button[type='submit'] {
                  @extend .button;
                  @extend .button-primary;
                  //padding: 2px 15px;
                  display: block;
                  margin: 0 auto;
                  width: 100%;
                }
              }
            }

            ul {
              li {
                display: flex;
                flex-wrap: wrap;
                font-size: 1.2rem;
                padding-bottom: 0.5rem;
                line-height: 2;
                a {
                  flex: 1;
                  font-weight: normal;
                  font-size: inherit;
                  color: #1a1a1a;
                }

                > ul {
                  width: 100%;
                  padding: 0.5rem 0 0.5rem 2rem;
                  > li {
                    display: flex;
                    &:not(:first-child) {
                      margin-top: 0.5rem;
                    }
                    input {
                      margin-top: 0.5rem;
                    }
                  }
                }

                &.active.expanded {
                  display: inline-block;
                  ul {
                    padding-top: 0.5rem;
                    padding-bottom: 0;
                  }
                }
              }

              // &.facetapi-facet-field-category{
              // }
            }

            @supports (appearance: none) or (-webkit-appearance: none) or
              (-moz-appearance: none) {
              label.element-invisible + .form-checkbox,
              label.element-invisible + .form-radio {
                display: inline-block;
              }

              input[type='checkbox'],
              input[type='radio'] {
                -webkit-appearance: none;
                position: relative;
                padding: 0;
                width: 15px;
                height: 15px;
                flex: 0 0 15px;
                transition: background 0.5s ease;
                outline: none;
                vertical-align: top;
                border: 1px solid #58585a;
                background: #fff;
                margin-top: 0.25rem;
                cursor: pointer;
              }

              input[type='checkbox']:checked,
              input[type='checkbox']:disabled:checked,
              input[type='radio']:checked,
              input[type='radio']:disabled:checked {
                background-color: $color-primary !important;
                border-color: $color-primary !important;
              }

              input[type='checkbox']:disabled,
              input[type='radio']:disabled {
                opacity: 0.5;
                background: #fff;
              }

              input[type='checkbox']:disabled:hover {
                border-color: #b5b5b5;
                cursor: default;
              }

              input[type='checkbox']:disabled:hover,
              input[type='radio']:disabled:hover {
                border-color: #b5b5b5;
                cursor: default;
              }
            }
          }

          @include less-than($screen-md) {
            .sidebar {
              .sidebar-trigger {
                display: flex !important;
                position: fixed;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 15px;
                font-size: 14px;
                z-index: 55;
                height: 100%;
                cursor: pointer;
                > a {
                  @include animated;
                  background-color: rgba(220, 123, 31, 0.9);
                  color: #fff;
                  padding: 0.2rem 0.5rem;
                  transform: rotate(-90deg);
                  position: absolute;
                  width: 150px;
                  height: 30px;
                  font-size: 14px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:hover {
                    background-color: rgba(220, 123, 31, 1);
                  }
                  &::after {
                    content: '\f067';
                    font-family: $font-awesome;
                    margin-left: 1rem;
                    font-weight: 100;
                    @include animated;
                  }
                }
              }
            }
          }

          @include larger-than($screen-md) {
            flex-direction: row;
            .sidebar {
              flex: 0 0 300px;
            }
            .main-content {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
