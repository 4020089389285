/*------------------------------------*\
  Koda Multicurrency
\*------------------------------------*/

.block-koda-multi-currency-display {
  padding-left: 1rem;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;

  .form-type-select {
    display: flex;
    font-size: 1.4rem;
    color: #000;
    select {
      color: #000;
    }
  }

  .currency {
    > label.control-label {
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        display: block;
        content: '';
        background-color: #000;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 11px;
        margin-left: 4px;
      }
    }
    /** NZD **/

    &.curr-loading {
      > label {
        &::after {
          background-image: url('../../images/loading-1.gif');
        }
      }
    }

    &--NZD {
      > label {
        &::after {
          background-image: url('../../images/currency--NZ.jpg');
        }
      }
    }
    &--USD {
      > label {
        &::after {
          background-image: url('../../images/currency--USD.png');
        }
      }
    }
    &--AUD {
      > label {
        &::after {
          background-image: url('../../images/currency--AUD.png');
        }
      }
    }
    &--EUR {
      > label {
        &::after {
          background-image: url('../../images/currency--EUR.jpg');
        }
      }
    }
    &--CLP {
      > label {
        &::after {
          background-image: url('../../images/currency--CLP.png');
        }
      }
    }
  }
}
