/*------------------------------------*\
  File: Modal
\*------------------------------------*/
.modal {
  /*
  ======================================================
   Search
  ======================================================
  */
  &--search {
    @extend .animation-element;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    display: none;
    z-index: -999;
    bottom: -100%;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .search-close {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: #f3f3f3;
      color: inherit;
      top: 0;
      right: 0;
      margin-top: -15px;
      margin-right: -15px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      @include animated;
    }
    form {
      position: relative;
      display: flex;
      align-items: center;
      width: 90%;
      padding: 20px;
      background-color: #333;

      > div {
        position: relative;
        width: 100%;
      }
      .views-exposed-widgets {
        display: flex;
        margin: 0;
        padding: 0;
        .views-exposed-widget {
          padding: 0;
          margin: 0;
          .form-submit {
            margin-top: 0;
          }
          &.views-widget-filter-search_api_views_fulltext {
            flex: 1;
          }
        }
      }
      .control-label {
        display: none;
      }
      .form-group {
        margin-bottom: 0;
        display: flex !important;
        float: none !important;
        flex: 1;
        .form-text {
          width: 100%;
          border-radius: 0;
        }
      }
      .form-control {
        height: 6rem;
        font-size: 2rem;
      }
      .btn {
        border-radius: 0 !important;
        width: auto;
        min-width: 8rem;
        height: 6rem;
        font-size: 0;
        margin: 0;
        background-image: url('../../images/icon--search.svg');
        background-position: center center;
        background-repeat: no-repeat;
      }

      // Responsive
      @include less-than($screen-md) {
        flex-direction: column;
        .form-text {
          text-align: center;
        }
      }

      @include larger-than($screen-md) {
        width: 600px;
      }
    } // form
  }

  &--show {
    @extend .animate__fadeIn;
    display: flex !important;
    visibility: visible;
    opacity: 1;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  }
}

/*
======================================================
 Newsletter Popup
======================================================
*/

#newsletter_subscription_popup {
  width: 100%;
  .nsp-form-wrapper {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      font-weight: normal;
    }
    h3 {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }

  @include larger-than($screen-lg) {
    .nsp-form-wrapper {
      h1 {
        font-size: 2.6rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
  }
  form#newsletter-signup-popup-subscription-form {
    width: 100%;
    > div {
      width: 100%;
      .form-item {
        display: flex;
        margin-bottom: 1rem;
        flex-direction: column;
      }
      .form-checkbox {
        margin-right: 1rem;
      }
      .form-submit {
        margin-top: 1rem;
        text-transform: uppercase;
        width: 100%;
      }

      // Responsive
      @include larger-than($screen-lg) {
        .form-submit {
          font-size: 3rem;
        }
      }
    }
  }

  .nsp-form-wrapper {
    display: flex;
    padding: 2rem;
    #logo-in-nsp {
      margin-bottom: 2rem;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

/*
======================================================
 Block: Modal
======================================================
*/

#modalContent {
  background-color: #ffff;
  border: 1px solid #eee;

  .modal-header {
    .ctools-close-modal {
      position: absolute;
      right: 0;
      padding: 1.5rem;
      top: 0;
      font-size: 4rem;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    .modal-header {
      button {
        outline: none;
        padding: 2px;
        height: 40px;
        line-height: 0;
      }
    }

    form {
      width: 100%;
      height: 100%;
      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
    }
  }

  .modal-body {
    position: relative;
    width: 100% !important;
    height: 100%;
  }

  /*------------------------------------*\
    Default modal
  \*------------------------------------*/

  &.modal-default {
    background-color: transparent;
    border: none;
    box-shadow: none;
    left: 0 !important;
    top: 0 !important;
    height: 100%;
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ctools-modal-dialog {
      position: relative;
      height: auto !important;
      background-color: #fff;
      border: 1px solid #e5e5e5;
    }
    .modal-content {
      height: auto;
      padding: 4rem 2rem;
    }

    .modal-header {
      .close {
        padding: 2px 10px;
      }
    }
    .modal-body {
      width: 100% !important;
    }

    @include larger-than($screen-md) {
      .ctools-modal-dialog {
        max-width: 80rem;
      }
      .modal-content {
        padding: 4rem;
      }
    }
  }
}

#modalBackdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

/** modal form **/
form {
  &[id*='koda-notify-stock-add-form'] {
    .form-item,
    .form-submit {
      margin-bottom: 1rem;
    }
  }
}

/*------------------------------------*\
  Modal Close
\*------------------------------------*/
html body #colorbox #cboxClose {
  background: url('../../images/icon--close2.svg') center center no-repeat !important;
  background-size: contain !important;
  width: 18px;
  height: 18px;
}

/*------------------------------------*\
  Retailers Modal
\*------------------------------------*/
#retailers-selection-pop-up {
  position: relative;
  width: 100%;
  display: grid;
  gap: 1.5em;

  > label {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
  }

  > div {
    @include animated;
    display: flex;
    align-items: stretch;
    justify-content: center;
    align-items: center;
    border: 1px solid #ece9e9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);

    &:hover {
      border: 1px solid #55c2ff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    }

    a {
      width: 100%;
      padding: 2rem;
    }

    img {
      width: auto;
      margin: auto;
    }
  }

  @include larger-than($screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 2rem 4rem;

    > label {
      grid-column: 1 / 3;
    }
  }
}
