/*------------------------------------*\
  Home Slider
\*------------------------------------*/

.home-slider {
  @apply tw-w-full;
  @apply tw-h-full;

  .node-home-slider {
    @apply tw-flex;
    @apply tw-items-center;
    @apply tw-justify-center;
    @apply tw-flex-col;
    @apply tw-w-full;

    /** Main Slider Image **/
    .field-name-field-slider-image {
      background-color: #000;
      @apply tw-w-full;
      @apply tw-hidden;
      height: $slider-height;

      > .field-items {
        > .field-item {
          height: $slider-height;
          img {
            height: $slider-height;
          }
        }
      }
    }

    /** Mobile Image Field **/
    /*
    .field-name-field-slider-mobile-image {
      background-color: #000;
      height: $slider-mobile-height;
      > .field-items {
        > .field-item {
          height: $slider-mobile-height;
          img {
            height: $slider-mobile-height;
          }
        }
      }
    }
    */

    .field-name-field-slider-mobile-image {
      background-color: #000;
      height: auto;

      > .field-items {
        > .field-item {
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .field-name-field-slider-image,
    .field-name-field-slider-mobile-image {
      &::before {
        @apply tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-10;
        content: '';
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 94.73%);
      }
      > .field-items {
        @apply tw-flex tw-flex-wrap;
        > .field-item {
          @apply tw-relative tw-w-full;
          img {
            @apply tw-w-full tw-object-cover;
          }
        }
      }
    }

    /** Content Field **/
    .slider-content {
      @apply tw-absolute tw-w-full tw-h-full tw-z-10 tw-top-0 tw-left-auto tw-right-auto;
      @apply tw-flex tw-flex-col tw-justify-center tw-content-center tw-items-center;
      @extend .tw-container;
      .btn {
        background-color: #44c8f5;
        border-radius: 8px;
        border: none;
        margin-top: 4rem;
        &:hover {
          background-color: $color-primary;
        }
      }
    }
    .field-name-field-content {
      @apply tw-w-full tw-flex tw-content-center tw-items-center;
      > .field-items {
        @apply tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center;
        > .field-item {
          color: #fff;
          @apply tw-items-center tw-text-center;
          img {
            @apply tw-mx-auto;
          }
        }
      }
    }

    /** Responsive **/
    @include larger-than(641px) {
      .field-name-field-slider-image {
        display: block;
      }
      .field-name-field-slider-mobile-image {
        display: none;
      }
    }
    @include larger-than($screen-lg) {
      .field-name-field-content {
        > .field-items > .field-item {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}
